import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface ViolinPlotContextType extends BasePlotContext<ViolinPlot, ViolinDesign> {
  // TODO: specifics
}

interface ViolinPlot extends BasePlot {
  // TODO: specifics
  bandwidth: ValueTracker<string>;
  custom: ValueTracker<number>;
  cut: ValueTracker<number>;
  gridSize: ValueTracker<number>;
  scale: ValueTracker<string>;
  scaleColor: ValueTracker<boolean>;
  isCollapsed: boolean;
}

interface ViolinDesign extends BaseDesign {
  // TODO: specifics
  width: ValueTracker<number>;
  saturation: ValueTracker<number>;
  lineWidth: ValueTracker<number>;
  split: ValueTracker<boolean>;
  dodge: ValueTracker<boolean>;
  violinInner: ValueTracker<string>;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  isCollapsed: boolean;
}

const initialState: ViolinPlot = {
  plotType: PlotType.violin,
  dataRange: undefined,
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  orientation: new ValueTracker("Vertical"),
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  bandwidth: new ValueTracker("Scott"),
  custom: new ValueTracker(0),
  cut: new ValueTracker(2),
  gridSize: new ValueTracker(100),
  scale: new ValueTracker("Area"),
  scaleColor: new ValueTracker(false),
  isCollapsed: true,
  hasHeaders: true,
  // if color by scale color should be true
};

const designInitialState: ViolinDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker("#1f77b4"),
  fillStyle: "",
  palette: new ValueTracker("Tab10"),
  saturation: new ValueTracker(0.75),
  lineWidth: new ValueTracker(0),
  width: new ValueTracker(0.8),
  split: new ValueTracker(false),
  dodge: new ValueTracker(true),
  // true by default unless color by is selected otherwise set to false
  violinInner: new ValueTracker("Box"),
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
};

export const ViolinPlotContext = createContext<ViolinPlotContextType>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const useViolin = () => {
  const context = useContext<ViolinPlotContextType>(ViolinPlotContext);
  return context;
};

export const ViolinPlotProvider = ({ children }: { children: React.ReactNode }) => {
  const [plot, setPlot] = useState<ViolinPlot>(initialState);
  const [design, setDesign] = useState<ViolinDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <ViolinPlotContext.Provider value={{ plot, updatePlot, design, updateDesign, resetContext, updatePlotWithReset }}>
      {children}
    </ViolinPlotContext.Provider>
  );
};
