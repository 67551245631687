import React, { ReactElement, ReactNode, useState } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogContent,
  DialogActions,
  Button,
  DialogTrigger,
} from "@fluentui/react-components";
import { Row } from "../Layout/Space";
import "../Notification.scss";

// With children / button trigger
export const CustomDialogWithTrigger: React.FC<{
  title: string;
  content: string | ReactElement;
  icon?: ReactNode;
  children?: ReactElement;
  onContinue?: () => Promise<void>;
}> = ({ title, content, icon, children, onContinue }) => {
  const [open, setIsOpen] = useState<boolean>(false);
  async function onContinueClick() {
    await onContinue();
    setIsOpen(!open);
  }

  return (
    <Dialog modalType="non-modal" open={open} onOpenChange={(_, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>{children}</DialogTrigger>
      <DialogSurface className="custom-dialog-surface">
        <DialogBody>
          <DialogTitle style={{ fontSize: 14 }}>
            <Row alignItems="center" gap={4}>
              {icon}
              {title}
            </Row>
          </DialogTitle>
          <DialogContent style={{ fontSize: 16 }}>{content}</DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={() => onContinueClick()}>
              Continue
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="subtle">Cancel</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

// With programmatic trigger
export const CustomDialog: React.FC<{
  title: string;
  content: string | ReactElement;
  icon?: ReactNode;
  buttonText?: string;
  onContinue?: (() => Promise<void>) | (() => void);
  disableContinue?: boolean;
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
}> = ({
  title,
  content,
  icon,
  buttonText = "Continue",
  openDialog = false,
  setOpenDialog,
  onContinue,
  disableContinue = false,
}) => {
  async function onContinueClick() {
    await onContinue();
    setOpenDialog(!open);
  }

  return (
    <Dialog modalType="non-modal" open={openDialog} onOpenChange={(_, data) => setOpenDialog(data.open)}>
      <DialogSurface className="custom-dialog-surface">
        <DialogBody>
          <DialogTitle style={{ fontSize: 16 }}>
            <Row alignItems="center" gap={4}>
              {icon}
              <span>{title}</span>
            </Row>
          </DialogTitle>
          <DialogContent style={{ fontSize: 16 }}>{content}</DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={() => onContinueClick()} disabled={disableContinue}>
              {buttonText}
            </Button>
            <Button appearance="subtle" onClick={() => setOpenDialog(false)}>
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
