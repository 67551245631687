import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { CountDesign } from "./Plots/CountPlotMVC";
import { BarDesign } from "./Plots/BarPlotMVC";
import { BoxDesign } from "./Plots/BoxPlotMVC";
import { ScatterDesign } from "./Plots/ScatterPlotMVC";
import { LineDesign } from "./Plots/LinePlotMVC";
import { RegressionDesign } from "./Plots/RegressionPlotMVC";
import { ViolinDesign } from "./Plots/ViolinPlotMVC";
import { Context } from "./Code";
import { useGeneratedCodeContext } from "../../hooks/useCode";
import { buildPythonInExcelCode } from "./MVCShared/CodeBuilder";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import ActionButtons from "./ActionButtons";
import { PairDesign } from "./Plots/PairPlot";
import { DisplotDesign } from "./Plots/Displot";

const FormComponentMap = {
  bar: BarDesign,
  box: BoxDesign,
  count: CountDesign,
  line: LineDesign,
  regression: RegressionDesign,
  scatter: ScatterDesign,
  violin: ViolinDesign,
  pairwise: PairDesign,
  distribution: DisplotDesign,
};

const BaseDesign = () => {
  const params = useParams();
  const selectedPlot = params?.plotType;
  const contextType = Context[params.plotType];
  const { generatedCode, clearGeneratedCode } = useGeneratedCodeContext();
  const { plot, resetContext } = useContext<typeof contextType>(contextType);

  const isDisabled = !plot?.dataRange || plot.outputCell.displayName === undefined;

  const handleRestart = async () => {
    await resetContext();
    await clearGeneratedCode();
  };
  const handleCreateClick = async () => {
    const addr = await getFullAddressFromRangeSelection(plot.outputCell);
    await writeToExecutionLocation(addr, await buildPythonInExcelCode(generatedCode, false));
  };

  const SelectedFormComponent = FormComponentMap[selectedPlot];

  return (
    <div className="base-design">
      <SelectedFormComponent />
      <ActionButtons onCreateClick={handleCreateClick} onRestart={handleRestart} disabled={isDisabled} />
    </div>
  );
};

export default BaseDesign;
