import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useRegression } from "../../../hooks/plots/useRegression";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import {
  Axes,
  Chart,
  Interval,
  LineStyle,
  Markers,
  RegEstimators,
  RegFunction,
  ValueTracker,
} from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  CollapsibleAttr,
  ColorPickerAttr,
  CheckBoxAttr,
  SpinnerAttr,
  LineKwargs,
  regressionErrorBar,
  AxisDropdownAttr,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs, CodeBuilder } from "../MVCShared/CodeBuilder";
import {
  dependencyEqualsValue,
  dependencyInValues,
  dependencyNotEqualsValue,
  fetchHeaders,
  onDataRangeChangeSelection,
} from "../MVCShared/PlotGeneratorUtils";

const RegressionContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign, updatePlotWithReset } = useRegression();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(regressionPlot, [...regressionPlot.baseAttrs, ...regressionPlot.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const MarkerAttr = DropdownAttr({
    label: "Marker Style:",
    value: design.marker,
    options: ["Point", "Circle", "Plus", "Star", "Diamond", "X", "None"],
    onChange: (_, e) => updateDesign({ marker: e.optionValue }),
    codeKey: "marker",
    codeValueMap: Markers,
    codeRequiresInteraction: true,
  });

  const errorBar = DropdownAttr({
    value: plot.errorBar,
    label: "Interval:",
    onChange: (_, data) => updatePlot({ errorBar: data.optionValue }),
    options: ["--None--", "CI", "SD"],
    codeKey: "x_ci",
    codeRequiresInteraction: true,
    codeValueMap: Interval,
    dataTestID: "error-bar",
  });

  const interval = SpinnerAttr({
    label: "Confidence Interval:",
    value: plot.interval,
    step: 1,
    max: 100,
    suffix: "%",
    onChange: (_, data) => updatePlot({ interval: parseInt(data) }),
    visibleDependencies: [dependencyInValues(errorBar, ["CI", "--None--"])],
    enabledDependencies: [dependencyInValues(errorBar, ["CI", "SD"])],
    codeRequiresInteraction: true,
    codeKey: "ci",
    dataTestID: "ci",
  });

  const regFunction = DropdownAttr({
    label: "Function:",
    value: plot.function,
    onChange: (_, data) => updatePlot({ function: data.optionValue }),
    options: ["--None--", "Linear", "Polynomial", "Logx", "Lowess", "Robust", "Logistic"],
    codeRequiresInteraction: true,
    dataTestID: "function",
  });

  regFunction.getCode = (code: CodeBuilder) => {
    if (plot.function instanceof ValueTracker && plot.function.isUpdated) {
      RegFunction[plot.function.value] ? code.plotAttrs.push(`${RegFunction[plot.function.value]}=True`) : "";
    }
  };

  const estimator = DropdownAttr({
    value: plot.estimator,
    onChange: (_, data) => updatePlot({ estimator: data.optionValue }),
    label: "Estimator:",
    options: ["--None--", "Mean", "Median", "Max", "Min"],
    placeholder: "None",
  });

  estimator.getCode = (code: CodeBuilder) => {
    const estimator = RegEstimators[plot.estimator.value];
    if (plot.estimator instanceof ValueTracker && plot.estimator.isUpdated) {
      estimator ? code.plotAttrs.push(`x_estimator=${estimator}`) : "";
    }
  };

  const xPartial = AxisDropdownAttr({
    value: plot.xPartial,
    onChange: (_, data) => updatePlot({ xPartial: data.optionValue }, "--Select--"),
    label: "X-Partial:",
    options: ["--Select--", ...plot.headers.filter((header) => header !== plot.xAxis && header !== plot.yAxis)],
    codeKey: "x_partial",
    hasHeaders: plot.hasHeaders,
  });

  xPartial.getCode = (code: CodeBuilder) => {
    plot.xPartial ? code.plotAttrs.push(`x_partial=df["${plot.xPartial}"]`) : "";
  };
  const yPartial = AxisDropdownAttr({
    value: plot.yPartial,
    onChange: (_, data) => updatePlot({ yPartial: data.optionValue }, "--Select--"),
    label: "Y-Partial:",
    options: ["--Select--", ...plot.headers.filter((header) => header !== plot.xAxis && header !== plot.yAxis)],
    codeKey: "y_partial",
    hasHeaders: plot.hasHeaders,
  });

  yPartial.getCode = (code: CodeBuilder) => {
    plot.yPartial ? code.plotAttrs.push(`y_partial=df["${plot.yPartial}"]`) : "";
  };

  const lineWidthAndStyle = LineKwargs({
    styleLabel: "Line Style:",
    options: ["Solid", "Dashed"],
    dropdownValue: design.lineStyle,
    onChangeStyle: (_, data) => updateDesign({ lineStyle: data.optionValue }),
    label: "Line Width:",
    value: design.lineWidth,
    step: 1,
    suffix: " px",
    onChange: (_, data) => updateDesign({ lineWidth: parseInt(data) }),
    codeValueMap: LineStyle,
    codeRequiresInteraction: true,
  });

  const RegressionErrorBar = regressionErrorBar({
    value: design.errorBarColor,
    spinnerValue: design.errorBarWidth,
    decimalValue: design.errorBarCapWidth,
    label: "Error Bar Color:",
    suffixLabel: "Error Bar Thickness:",
    decimalLabel: "Error Bar Cap Width:",
    onChange: (hexColor) => updateDesign({ errorBarColor: `#${hexColor}` }),
    onValueChange: (_, data) => updateDesign({ errorBarWidth: parseInt(data) }),
    onDecimalChange: (_, data) => updateDesign({ errorBarCapWidth: parseFloat(data) }),
    suffix: " px",
    suffixStep: 1,
    decimalStep: 0.1,
    yAxis: plot.yAxis,
    xAxis: plot.xAxis,
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "None")],
    codeRequiresInteraction: true,
  });

  const regressionPlot: Chart = {
    plotType: PlotType.regression,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) =>
          onDataRangeChangeSelection(newSelection, updatePlotWithReset),
      }),
      CheckBoxAttr({
        label: "Data has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      AxisDropdownAttr({
        value: plot.xAxis,
        onChange: (_, data) => {
          updatePlot({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        label: "X-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "x",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.yAxis,
        onChange: (_, data) => {
          updatePlot({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "y",
        hasHeaders: plot.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Regression", tooltip: "Select regression function type and bounding" }),
      regFunction,
      SpinnerAttr({
        label: "Order:",
        value: plot.order,
        step: 1,
        onChange: (_, data) => updatePlot({ order: data }),
        codeKey: "order",
        visibleDependencies: [dependencyEqualsValue(regFunction, "Polynomial")],
        dataTestID: "order",
      }),
      CheckBoxAttr({
        label: "Truncate:",
        onChange: (_, data) => updatePlot({ truncate: data.checked }),
        value: plot.truncate,
        codeRequiresInteraction: true,
        codeKey: "truncate",
        dataTestID: "truncate",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Output", tooltip: "Determine where visualization will render" }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: plot.isCollapsed,
        toggle: () => updatePlot({ isCollapsed: !plot.isCollapsed }),
        children: [
          HeadingAttr({
            title: "Error",
            tooltip: "Measure error in data",
          }),
          estimator,
          errorBar,
          interval,
          SpinnerAttr({
            label: "Bootstrap Samples:",
            value: plot.bootstrapSamples,
            step: 1,
            onChange: (_, data) => updatePlot({ bootstrapSamples: data }),
            codeKey: "n_boot",
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
          }),
          SpinnerAttr({
            label: "Seed:",
            value: plot.seed,
            step: 1,
            onChange: (_, data) => updatePlot({ seed: data }),
            codeKey: "seed",
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Axes",
        tooltip: "Set scale for axes",
      }),
      DropdownAttr({
        value: plot.newXAxisScale,
        onChange: (_, data) => updatePlot({ newXAxisScale: data.optionValue }),
        label: "X-Axis Scale:",
        options: ["Linear", "Log"],
        callKey: "xscale",
        codeValueMap: Axes,
        codeRequiresInteraction: true,
      }),
      DropdownAttr({
        value: plot.newYAxisScale,
        onChange: (_, data) => updatePlot({ newYAxisScale: data.optionValue }),
        label: "Y-Axis Scale:",
        options: ["Linear", "Log"],
        callKey: "yscale",
        codeValueMap: Axes,
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Partials", tooltip: "Select confounding variables to regress out of x and y variables" }),
      xPartial,
      yPartial,
      DividerAttr(),
      HeadingAttr({ title: "Jitter", tooltip: "Add uniform random noise to either the x or y variables" }),
      SpinnerAttr({
        label: "X-Jitter:",
        value: plot.xJitter,
        step: 0.1,
        onChange: (_, data) => updatePlot({ xJitter: data }),
        codeKey: "x_jitter",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Jitter:",
        value: plot.yJitter,
        step: 0.1,
        onChange: (_, data) => updatePlot({ yJitter: data }),
        codeKey: "y_jitter",
        codeRequiresInteraction: true,
      }),
    ],
    designAttrs: [
      HeadingAttr({ title: "Chart Info" }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "X-Axis Label:",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "Y-Axis Label:",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks:",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks:",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      ColorPickerAttr({
        value: design.fill,
        label: "Fill:",
        onChange: (hexColor) => updateDesign({ fill: `#${hexColor}` }),
        codeKey: "color",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Markers" }),
      MarkerAttr,
      DividerAttr(),
      HeadingAttr({ title: "Line Style" }),
      lineWidthAndStyle,
      LabelAttr({
        label: "Line Label:",
        value: design.lineLabel,
        placeholder: "Label",
        posKey: "label",
        onChange: (event) => updateDesign({ lineLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isDesignCollapsed,
        toggle: () => updateDesign({ isDesignCollapsed: !design.isDesignCollapsed }),
        children: [HeadingAttr({ title: "Error" }), RegressionErrorBar],
      }),
    ],
  };
  return regressionPlot;
};

const RegressionForm = () => {
  const regressionPlot = RegressionContext();
  return buildReactFromAttrs(regressionPlot.baseAttrs);
};

export const RegressionDesign = () => {
  const regressionDesign = RegressionContext();
  return buildReactFromAttrs(regressionDesign.designAttrs);
};

export default RegressionForm;
