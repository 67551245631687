import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface RegressionPlotContextType extends BasePlotContext<RegressionPlot, RegressionDesign> {
  // TODO: specifics
}

interface RegressionPlot extends BasePlot {
  // TODO: specifics
  interval: ValueTracker<number>;
  bootstrapSamples: ValueTracker<number>;
  seed: ValueTracker<number>;
  newXAxisScale: ValueTracker<string>;
  newYAxisScale: ValueTracker<string>;
  xPartial: string;
  yPartial: string;
  xJitter: ValueTracker<number>;
  yJitter: ValueTracker<number>;
  function: ValueTracker<string>;
  order: ValueTracker<number>;
  truncate: ValueTracker<boolean>;
  estimator: ValueTracker<string>;
  errorBar: ValueTracker<string>;
  isCollapsed: boolean;
}

interface RegressionDesign extends BaseDesign {
  // TODO: specifics
  lineWidth: ValueTracker<number>;
  lineStyle: ValueTracker<string>;
  lineLabel: string;
  marker: ValueTracker<string>;
  stroke: ValueTracker<number>;
  gap: ValueTracker<number>;
  errorBarColor: ValueTracker<string>;
  errorBarWidth: ValueTracker<number>;
  errorBarCapWidth: ValueTracker<number>;
  isDesignCollapsed: boolean;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
}

const initialState: RegressionPlot = {
  plotType: PlotType.regression,
  dataRange: undefined,
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  newXAxisScale: new ValueTracker("Linear"),
  newYAxisScale: new ValueTracker("Linear"),
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  xAxisScale: "",
  yAxisScale: "",
  estimator: new ValueTracker("--None--"),
  errorBar: new ValueTracker("--None--"),
  interval: new ValueTracker(95),
  bootstrapSamples: new ValueTracker(1000),
  seed: new ValueTracker(0),
  xPartial: "",
  yPartial: "",
  xJitter: new ValueTracker(0),
  yJitter: new ValueTracker(0),
  function: new ValueTracker("Linear"),
  order: new ValueTracker(2),
  truncate: new ValueTracker(true),
  isCollapsed: true,
  hasHeaders: true,
};

const designInitialState: RegressionDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker("#1f77b4"),
  marker: new ValueTracker("None"),
  stroke: new ValueTracker(5),
  gap: new ValueTracker(0),
  // In the original figma, cannot find documentation for these
  lineStyle: new ValueTracker("Solid"),
  lineWidth: new ValueTracker(3),
  lineLabel: "",
  errorBarColor: new ValueTracker("black"),
  errorBarWidth: new ValueTracker(2),
  errorBarCapWidth: new ValueTracker(0.5),
  isDesignCollapsed: true,
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
};

export const RegressionPlotContext = createContext<RegressionPlotContextType>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const useRegression = () => {
  const context = useContext<RegressionPlotContextType>(RegressionPlotContext);
  return context;
};

export const RegressionPlotProvider = ({ children }: { children: React.ReactNode }) => {
  const [plot, setPlot] = useState<RegressionPlot>(initialState);
  const [design, setDesign] = useState<RegressionDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <RegressionPlotContext.Provider
      value={{
        plot,
        updatePlot,
        design,
        updateDesign,
        resetContext,
        updatePlotWithReset,
      }}
    >
      {children}
    </RegressionPlotContext.Provider>
  );
};
