import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useDisplot } from "../../../hooks/plots/useDisplot";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import { Chart, LegendPosition } from "../MVCShared/types";
import {
  AxisDropdownAttr,
  CheckBoxAttr,
  CollapsibleAttr,
  ColorPickerAttr,
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  LegendAttr,
  OutputAttr,
  PaletteAttr,
  SpinnerAttr,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs } from "../MVCShared/CodeBuilder";
import {
  dependencyEqualsValue,
  dependencyNotEqualsValue,
  fetchHeaders,
  onDataRangeChangeSelection,
} from "../MVCShared/PlotGeneratorUtils";
import { Facet } from "./FacetGridAttr";

const DisplotContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign } = useDisplot();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(DisplotChart, [...DisplotChart.baseAttrs, ...DisplotChart.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const ColorBy = AxisDropdownAttr({
    value: plot.colorBy,
    onChange: (_, data) => updatePlot({ colorBy: data.optionValue }, "--None--"),
    label: "Color By:",
    options: ["--None--", ...plot.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: plot.hasHeaders,
  });

  const LegendCheckbox = CheckBoxAttr({
    value: design.legend,
    onChange: (_, e) => updateDesign({ legend: e.checked }),
    label: "Legend:",
    codeKey: "legend",
    codeRequiresInteraction: true,
    dataTestID: "legend",
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position:",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
    enabledDependencies: [dependencyNotEqualsValue(LegendCheckbox, false)],
  });

  const DisplotChart: Chart = {
    plotType: PlotType.distribution,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) => {
          onDataRangeChangeSelection(newSelection, updatePlot);
        },
      }),
      CheckBoxAttr({
        label: "Data Has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      DropdownAttr({
        value: plot.xAxis,
        onChange: (_, data) => {
          updatePlot({ xAxis: data.optionValue }, "--Select--");
        },
        dataTestID: "x-axis",
        label: "X-Axis",
        options: ["--Select--", ...plot.headers],
        codeKey: "x",
      }),
      DropdownAttr({
        value: plot.yAxis,
        onChange: (_, data) => {
          updatePlot({ yAxis: data.optionValue }, "--Select--");
        },
        dataTestID: "yAxis",
        label: "Y-Axis",
        options: ["--Select--", ...plot.headers],
        codeKey: "y",
      }),
      ColorBy,
      DividerAttr(),
      HeadingAttr({ title: "Output", tooltip: "Determine where visualization will render" }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: plot.isCollapsed,
        toggle: () => {
          updatePlot({ isCollapsed: !plot.isCollapsed });
        },
        children: [
          DropdownAttr({
            value: plot.col,
            onChange: (_, data) => {
              updatePlot({ col: data.optionValue }, "--Select--");
            },
            dataTestID: "col",
            label: "Facet Grid Column:",
            options: ["--Select--", ...plot.headers],
            codeKey: "col",
          }),
          DropdownAttr({
            value: plot.row,
            onChange: (_, data) => {
              updatePlot({ row: data.optionValue }, "--Select--");
            },
            dataTestID: "row",
            label: "Facet Grid Row:",
            options: ["--Select--", ...plot.headers],
            codeKey: "row",
          }),
          Facet({
            sharexVal: plot.shareX,
            shareyVal: plot.shareY,
            facetPalette: design.facetPalette,
            xlim: design.xlim,
            ylim: design.ylim,
            codeKey: "facet_kws",
            onChangeSharex: (_, e) => updatePlot({ shareX: e.checked }),
            onChangeSharey: (_, e) => updatePlot({ shareY: e.checked }),
            codeRequiresInteraction: true,
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Subplots", tooltip: "" }),
      DropdownAttr({
        value: plot.kind,
        onChange: (_, data) => {
          updatePlot({ kind: data.optionValue }, "--None--");
        },
        dataTestID: "kind",
        label: "Kind:",
        options: ["--None--", "Ecdf", "Hist", "Kde"],
        codeValueMap: { Ecdf: "ecdf", Hist: "hist", Kde: "kde" },
        codeRequiresInteraction: true,
        codeKey: "kind",
      }),
      DividerAttr(),
    ],
    designAttrs: [
      HeadingAttr({ title: "Chart Info" }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks:",
        value: design.xticks,
        step: 5,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks:",
        value: design.yticks,
        step: 5,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      ColorPickerAttr({
        value: design.fill,
        label: "Fill:",
        onChange: (hexColor) => updateDesign({ fill: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        label: "Facet Grid Palette",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Scale", tooltip: "Set chart size" }),
      SpinnerAttr({
        label: "Height:",
        value: design.height,
        step: 0.1,
        max: 2 ** 32 - 1,
        suffix: " in",
        onChange: (_, data) => updateDesign({ height: parseFloat(data) }),
        codeKey: "height",
        codeRequiresInteraction: true,
        dataTestID: "height",
      }),
      SpinnerAttr({
        label: "Aspect:",
        value: design.aspect,
        step: 0.1,
        suffix: " in",
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ aspect: parseFloat(data) }),
        codeKey: "aspect",
        codeRequiresInteraction: true,
        dataTestID: "aspect",
      }),
      SpinnerAttr({
        label: "Column Wrap Width",
        value: design.colWrap,
        step: 1,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ colWrap: data }),
        codeKey: "col_wrap",
        codeRequiresInteraction: true,
        dataTestID: "col-wrap",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend Style" }),
      LegendCheckbox,
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top:",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right:",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          HeadingAttr({ title: "Facet Color" }),
          PaletteAttr({
            value: design.facetPalette,
            onChange: (_, data) => {
              updateDesign({ facetPalette: data.optionText });
            },
            placeholder: "Accent",
            dataTestID: "facetPalette",
          }),
          DividerAttr(),
          HeadingAttr({ title: "Axis Limits" }),
          SpinnerAttr({
            label: "X-Limit:",
            value: design.xlim,
            step: 1,
            max: 180,
            min: -180,
            onChange: (_, data) => updateDesign({ xlim: data }),
            codeRequiresInteraction: true,
            dataTestID: "xlim",
          }),
          SpinnerAttr({
            label: "Y-Limit:",
            value: design.ylim,
            step: 1,
            max: 180,
            min: -180,
            onChange: (_, data) => updateDesign({ ylim: data }),
            codeRequiresInteraction: true,
            dataTestID: "ylim",
          }),
        ],
      }),
      DividerAttr(),
    ],
  };
  return DisplotChart;
};

const DisplotForm = () => {
  const DisplotChart = DisplotContext();
  return buildReactFromAttrs(DisplotChart.baseAttrs);
};

export const DisplotDesign = () => {
  const DisplotDesign = DisplotContext();
  return buildReactFromAttrs(DisplotDesign.designAttrs);
};

export default DisplotForm;
