import React, { useEffect } from "react";
import { Tab, TabList } from "@fluentui/react-components";
import Switch from "react-switch";
import { useParams, useNavigate } from "react-router-dom";
import Code from "./Code";
import BaseDesign from "./BaseDesign";
import BaseSetup from "./BaseSetup";
import "./Viz.scss";

import { preloadPyWorker } from "./viz-preview";
function CoreViz() {
  const navigate = useNavigate();
  const { plotType, tab = "setup" } = useParams();

  useEffect(() => {
    preloadPyWorker();
  }, []);

  return (
    <div className="core-viz">
      <TabList selectedValue={tab} onTabSelect={(_, selectedTab) => navigate(`/viz/${plotType}/${selectedTab.value}`)}>
        <Tab value="setup">Setup</Tab>
        <Tab value="design">Design</Tab>
        <Tab value="preview">Preview</Tab>
        <div className="editor-switch">
          <Switch
            data-testid="code-switch"
            className="react-switch"
            checked={false}
            onChange={() => {
              navigate(`/viz/${plotType}/previewer`);
            }}
            offColor="#FFFFFF"
            offHandleColor="#6A718A"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            height={19}
            width={36}
            activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.2)"
          />
          <span
            style={{
              marginRight: "8px",
              fontSize: "13px",
              color: "#6A718A",
            }}
          >
            Code Mode
          </span>
        </div>
      </TabList>
      <div className="tab-content">
        {(tab === "setup" || tab === undefined) && <BaseSetup />}
        {tab === "design" && <BaseDesign />}
        {tab === "preview" && <Code />}
      </div>
    </div>
  );
}
export default CoreViz;
