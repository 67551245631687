import {
  RegistryRequest,
  RegistryResponse,
  WorkerInfo,
  PyWorker,
  getPyWorker as verboseGetPyWorker,
} from "xlcommon/src/pyscript/worker";
import { v4 as uuidv4 } from "uuid";
import { getValueTypesOfRange, convertDataToTyped, readFromRange } from "xlcommon/src/excel/excel-grid-utils";

export const INIT_CODE = `import numpy as np
import pandas as pd
import matplotlib.pyplot as plt
import seaborn as sns
import statsmodels`;

const workerInfo: WorkerInfo = {
  worker: undefined,
  terminate: null,
  loadingFlag: false,
  config: {
    pyodideVersion: "0.24.1",
    pyodidePackages: ["pandas", "matplotlib", "statsmodels"],
    pyscriptApps: [],
    micropipPackages: ["seaborn==0.12.2"],
    initCode: INIT_CODE,
  },
};

export async function preloadPyWorker() {
  await getPyWorker();
}

export async function getPyWorker() {
  return await verboseGetPyWorker(workerInfo);
}

export async function buildChartPreview(code: string, dataFrame: string, fullAddress: string): Promise<string> {
  try {
    const py: PyWorker = await getPyWorker();
    const randomId = uuidv4();

    let excel_data: any = await readFromRange(fullAddress);

    const valueType = await getValueTypesOfRange(fullAddress);
    const converted = convertDataToTyped(excel_data, valueType);

    await py.setRequest(randomId, {
      address: "CHART",
      // Chart preview always uses PyScript, so always uses REF -- no need to check executionLocation or handle xl()
      code: `data = REF("TABLE", lookup_source="${randomId}")\ndf = pd.DataFrame(${dataFrame})\n\n${code}`,
      mode: 2,
      data: { TABLE: converted },
    } as RegistryRequest);
    await py.runPythonCode(randomId);
    const resp: RegistryResponse = await py.getResponse(randomId);
    const typeName = resp.Python_typeName;
    if (typeName === "error") return resp.error;
    if (typeName === "Base64Image") return `<img src="data:image/png;base64,${resp.result}">`;
    return `Error: Returned value is not an image -- ${resp.result}`;
  } catch (err) {
    console.log(err);
    return String(err);
  }
}
