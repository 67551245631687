const PREFIX = "_xltb__";

class Environment {
  has(name: string): boolean {
    return `${PREFIX}${name}` in sessionStorage;
  }
  read(name: string): string {
    const value = sessionStorage.getItem(`${PREFIX}${name}`);
    return value ?? "";
  }
  write(name: string, value: string) {
    sessionStorage.setItem(`${PREFIX}${name}`, value);
  }
  delete(name: string) {
    sessionStorage.removeItem(`${PREFIX}${name}`);
  }

  // These are set in apps/xltb/api/xltb/routes/root_xxx.py:inject_environment_variables
  get ASSISTANT_CLIENT_SOURCE(): string {
    return this.read("ASSISTANT_CLIENT_SOURCE");
  }
  get ASSISTANT_ROUTE_PATH_PREFIX(): string {
    return this.read("ASSISTANT_ROUTE_PATH_PREFIX");
  }
  get BASE_URL(): string {
    return this.read("BASE_URL");
  }
  get CLOUD_URL(): string {
    return this.read("CLOUD_URL");
  }
  get HEAP_ID(): number {
    return parseInt(this.read("HEAP_ID"));
  }
  get MANIFEST_VERSION_IN_STORE(): string {
    return this.read("MANIFEST_VERSION_IN_STORE");
  }
  get PYSCRIPT_NAMESPACE(): string {
    return this.read("PYSCRIPT_NAMESPACE");
  }
  get UNLOCKED(): boolean {
    return this.read("UNLOCKED") === "true";
  }

  get manifestVersion(): string {
    return this.read("manifestVersion");
  }

  get signedIn(): boolean {
    if (!this.has("signedIn")) return false;
    return this.read("signedIn") === "true";
  }

  set signedIn(state: boolean) {
    this.write("signedIn", state ? "true" : "false");
  }

  get pyscriptEnabled(): boolean {
    if (!this.has("pyscriptEnabled")) return false;
    return this.read("pyscriptEnabled") === "true";
  }
  set pyscriptEnabled(state: boolean) {
    this.write("pyscriptEnabled", state ? "true" : "false");
  }
}

const Env = new Environment();
export default Env;

// The manifest file main URL contains `?version=x.x.x`
// The Start Page must call this method with the query params
// so the manifest version is properly set within Env
export function setManifestVersionFromStartPage(queryParams: string) {
  const verstr = "manifestVersion";
  const qp = new URLSearchParams(queryParams);
  if (qp.has("version")) {
    Env.write(verstr, qp.get("version") || "");
  }
}

export function userManifestNeedsUpdate(): boolean {
  if (Env.manifestVersion === "") return false; // TODO: remove this once manifest version in store is updated
  const installed = Env.manifestVersion.split(".");
  const deployed = Env.MANIFEST_VERSION_IN_STORE.split(".");
  for (let i = 0; i < deployed.length; i++) {
    // Check for missing version segment
    if (installed.length < i + 1) return true;
    try {
      const a = parseInt(installed[i]);
      const b = parseInt(deployed[i]);
      if (a < b) return true;
      if (a > b) return false;
      // if (a == b) continue to next segment
    } catch (err) {
      console.log(`Error parsing version: ${err}`);
      return true;
    }
  }
  return false;
}
