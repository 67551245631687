import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardHeader, Text } from "@fluentui/react-components";
import {
  ChevronDown20Filled,
  CodePy16Regular,
  MathFormula16Regular,
  DeleteRegular,
  EditRegular,
} from "@fluentui/react-icons";
import { Row } from "../../components/Layout/Space";
import { ProjectItemProps } from "../Projects/ProjectFolder";
import IconHeader from "../../components/Typography";
import { PlaceholderItem } from "../../components/PlaceholderItem";
import { PopoverMenuButton } from "../../components/PopoverMenuButton";
import { SnippetViewer } from "./SnippetViewer";
import { ExportSnippetProps } from "./ExportSnippet";
import ProjectsService from "../../../data/projects/projects-api";
import "./SnippetItem.scss";

export const LANGUAGE_SUFFIXES = [
  { suffix: ".py", language: "Python" },
  { suffix: ".xl", language: "Excel Formula" },
];
const VALID_FILE_SUFFIX = new Set(LANGUAGE_SUFFIXES.map(({ suffix }) => suffix.slice(1)));

export function SnippetItem({ project, item }: ProjectItemProps) {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement>();

  useEffect(() => {
    (async () => {
      if (expanded && !code) {
        setIsDownloadingFile(true);
        try {
          const res = await ProjectsService.downloadFileByName(project.id, item.name);
          // Must avoid rendering errors in code editor
          if (res.ok) {
            const fileContent = await res.text();
            setCode(fileContent);
            setIsDownloadingFile(false);
          }
        } catch (e) {
          console.log("There was an error downloading code snippet files.");
          console.log(e);
          setIsDownloadingFile(false);
        }
      }
    })();
  }, [expanded]);

  function openEditor() {
    navigate(`/snippets/export/edit`, {
      state: {
        code,
        project,
        fileName: item.name,
      } as ExportSnippetProps,
    });
  }

  async function deleteItem(projId: string, fileName: string) {
    try {
      await ProjectsService.deleteFileByName(projId, fileName);
    } catch (e) {
      // TODO: Get feedback for error messaging to user
      console.log(e);
    }
  }

  // Only show files with known suffix
  const fileSuffix = item.name.split(".").slice(-1)[0].toLowerCase();
  if (!VALID_FILE_SUFFIX.has(fileSuffix) || item.metadata["code_snippet"] === undefined) return null;

  return (
    <div className="snippet-item">
      <Card
        className={expanded ? "cell-card expanded" : "cell-card"}
        style={{ marginBottom: 12 }}
        appearance="outline"
        ref={cardRef}
      >
        <CardHeader
          className="header-row"
          style={{ padding: 4 }}
          header={
            <IconHeader
              Icon={fileSuffix === "py" ? <CodePy16Regular /> : <MathFormula16Regular />}
              text={
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: expanded ? "calc(100vw - 190px)" : "calc(100vw - 160px)",
                  }}
                >
                  <Text style={{ fontWeight: 600 }}>{item.name}</Text>
                </div>
              }
            />
          }
          action={
            <Row gap={0}>
              {expanded && (
                <PopoverMenuButton
                  kind="file"
                  id={project?.id}
                  actions={[
                    {
                      actionName: "Edit",
                      onClickAction: () => openEditor(),
                      icon: <EditRegular />,
                    },
                    {
                      actionName: "Delete",
                      onClickAction: async () => await deleteItem(project?.id, item.name),
                      icon: <DeleteRegular />,
                    },
                  ]}
                />
              )}
              <Button
                style={{ cursor: "pointer", border: "none" }}
                aria-label="Expand card"
                icon={<ChevronDown20Filled />}
                onClick={() => setExpanded(!expanded)}
              />
            </Row>
          }
        />
        {expanded && (
          <div style={{ marginRight: 10 }}>
            {isDownloadingFile ? <PlaceholderItem /> : <SnippetViewer name={item.name} code={code} />}
          </div>
        )}
      </Card>
    </div>
  );
}
