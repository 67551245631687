import React, { Suspense, useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { Button, Spinner, Tooltip } from "@fluentui/react-components";
import { Copy20Regular, CodeBlockRegular, EyeFilled } from "@fluentui/react-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CodeMirror, { EditorView, KeyBinding, Prec, ReactCodeMirrorRef, keymap } from "@uiw/react-codemirror";
import { acceptCompletion } from "@codemirror/autocomplete";
import { python } from "@codemirror/lang-python";
import { useGeneratedCodeContext } from "../../hooks/useCode";
import { Row } from "../../components/Layout/Space";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import { buildPythonInExcelCode, buildPyodideCode } from "./MVCShared/CodeBuilder";
import { buildChartPreview } from "./viz-preview";
import ActionButtons from "./ActionButtons";
import Trace from "./Trace";
import {
  PlotContext,
  BoxPlotContext,
  CountPlotContext,
  LinePlotContext,
  ScatterPlotContext,
  RegressionPlotContext,
  ViolinPlotContext,
  PairPlotContext,
  DisplotContext,
} from "../../hooks";
import { editorStyles } from "./VizSharedComponents/Themes";
import { codeDarkTheme } from "../../theme";
import { editorSetup } from "xlcommon/src/pyscript/editor";

export const Context = {
  bar: PlotContext,
  box: BoxPlotContext,
  count: CountPlotContext,
  line: LinePlotContext,
  scatter: ScatterPlotContext,
  regression: RegressionPlotContext,
  violin: ViolinPlotContext,
  pairwise: PairPlotContext,
  distribution: DisplotContext,
};

const Code = () => {
  const params = useParams();
  const { generatedCode, clearGeneratedCode, codeResult } = useGeneratedCodeContext();
  const contextType = Context[params.plotType];
  const { plot, resetContext } = useContext<typeof contextType>(contextType);
  const cm = useRef<ReactCodeMirrorRef>();
  const navigate = useNavigate();

  const isDisabled = !plot?.dataRange || plot.outputCell.displayName === undefined;

  const handleRestart = async () => {
    await resetContext();
    clearGeneratedCode();
  };
  const handleCreateClick = async () => {
    const addr = await getFullAddressFromRangeSelection(plot.outputCell);
    await writeToExecutionLocation(addr, await buildPythonInExcelCode(generatedCode, false));
  };

  const [previewCodeResult, setPreviewCodeResult] = useState<string>();
  const [previewLoading, setPreviewLoading] = useState<boolean>();

  // https://pyodide.org/en/stable/usage/api/js-api.html#pyodide-canvas
  const [trace, setTrace] = useState<string>();
  useEffect(() => {
    if (generatedCode?.source) {
      (async () => {
        try {
          setPreviewLoading(true);

          const sourceAddr = await getFullAddressFromRangeSelection(generatedCode.source);
          if (!sourceAddr) return;

          const dataFrame = plot.hasHeaders ? "data[1:], columns=data[0]" : "data";

          await buildChartPreview(await buildPyodideCode(generatedCode), dataFrame, sourceAddr).then((result) => {
            const hasTrace = result.indexOf("<img") === -1;
            if (!hasTrace) {
              setPreviewCodeResult(result);
            } else {
              setTrace(result);
            }
            setPreviewLoading(false);
          });
        } catch (e) {
          setPreviewLoading(false);
        }
      })();
    }
    // TODO: Consider other dependencies
  }, [generatedCode, plot.hasHeaders]);

  if (!generatedCode?.source) {
    return <p data-testid="preview-message">Configure your visualization in the setup tab to begin generating code.</p>;
  }

  const customKeyBindings: KeyBinding[] = [{ key: "Tab", run: acceptCompletion, preventDefault: false }];

  function editorRefCallback(editor: ReactCodeMirrorRef) {
    if (editor?.editor && editor?.state && editor?.view) {
      cm.current = editor; // Save reference
    }
  }

  return (
    <div className="preview">
      <Suspense>
        {previewLoading && (
          <Row justifyContent="center" gap={15}>
            <div style={{ margin: "10px 0" }}>
              <Spinner
                size="tiny"
                label={<span style={{ fontWeight: 400 }}>Loading plot preview for selected data range...</span>}
                labelPosition="after"
              />
            </div>
          </Row>
        )}
        {previewCodeResult && (
          <Row>
            <div className="plot-preview" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewCodeResult) }} />
          </Row>
        )}
        {trace && <Trace content={trace} />}
        <Row justifyContent="space-between" alignItems="center">
          <b data-testid="readonly-header">Preview Code (read-only)</b>
          <div style={{ display: "flex", gap: 5 }}>
            <Tooltip
              content="Chart not looking as expected? Head to the Design Tab to adjust its style."
              relationship="label"
              withArrow
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <EyeFilled />
              </span>
            </Tooltip>
            <Tooltip content="Copy code" relationship="label" withArrow>
              <div>
                {" "}
                {/* This div is necessary to prevent the Tooltip from conflicting with CopyToClipboard component listeners simply */}
                <CopyToClipboard text={codeResult}>
                  <Button appearance="subtle" icon={<Copy20Regular />} />
                </CopyToClipboard>
              </div>
            </Tooltip>
            <Tooltip content="Save as code snippet" relationship="label" withArrow>
              <Button
                appearance="subtle"
                icon={<CodeBlockRegular />}
                onClick={() => navigate("/snippets/export", { state: { code: codeResult } })}
              />
            </Tooltip>
          </div>
        </Row>
        <CodeMirror
          value={codeResult}
          editable={false}
          readOnly={true}
          ref={editorRefCallback}
          basicSetup={editorSetup}
          theme={codeDarkTheme}
          extensions={[Prec.highest(keymap.of(customKeyBindings)), python(), EditorView.lineWrapping, editorStyles]}
          height="100%"
          style={{ overflow: "hidden", border: "none", borderRadius: "4px" }}
          data-testid="readonly-editor"
        />
        <ActionButtons onCreateClick={handleCreateClick} onRestart={handleRestart} disabled={isDisabled} />
      </Suspense>
    </div>
  );
};

export default Code;
