import React, { useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Tooltip } from "@fluentui/react-components";
import { CodeBlockRegular } from "@fluentui/react-icons";
import readOnlyRangesExtension from "codemirror-readonly-ranges";
import CodeMirror, {
  EditorState,
  EditorView,
  KeyBinding,
  Prec,
  ReactCodeMirrorRef,
  keymap,
} from "@uiw/react-codemirror";
import { acceptCompletion } from "@codemirror/autocomplete";
import { python } from "@codemirror/lang-python";
import { editorSetup } from "xlcommon/src/pyscript/editor";
import { Context } from "./Code";
import { editorStyles } from "./VizSharedComponents/Themes";
import ActionButtons from "./ActionButtons";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import { useGeneratedCodeContext } from "../../hooks/useCode";
import { codeDarkTheme } from "../../theme";

export const editableLine = `''' Editable beyond this line '''`;

const UserEditor = () => {
  const { generatedCode, clearGeneratedCode, typedCode, setTypedCode } = useGeneratedCodeContext();
  const { plotType } = useParams();
  const contextType = Context[plotType];
  const { plot, resetContext } = useContext<typeof contextType>(contextType);
  const navigate = useNavigate();
  const cm = useRef<ReactCodeMirrorRef>();

  const handleRestart = async () => {
    await resetContext();
    await clearGeneratedCode();
  };
  const handleCreateClick = async () => {
    const cleanedTypedCode = typedCode.replace(editableLine, "");
    const addr = await getFullAddressFromRangeSelection(plot.outputCell);
    await writeToExecutionLocation(addr, cleanedTypedCode);
  };

  if (!generatedCode?.source) {
    return <p>Configure your visualization in the setup tab to begin generating code.</p>;
  }

  const getReadOnlyRanges = (targetState: EditorState): Array<{ from: number | undefined; to: number | undefined }> => {
    const typedCodeArray = typedCode.split(`\n`);
    const readOnlyEnd = typedCodeArray.findIndex((line) => line.includes(editableLine)) + 1;

    return [
      {
        from: undefined,
        to: targetState.doc.line(readOnlyEnd).to,
      },
    ];
  };

  const customKeyBindings: KeyBinding[] = [
    { key: "Tab", run: acceptCompletion, preventDefault: false },
    // { key: "Tab", run: () => runCompletion(cm.current, [".", "(", "["]), preventDefault: true },
  ];

  function editorRefCallback(editor: ReactCodeMirrorRef) {
    if (editor?.editor && editor?.state && editor?.view) {
      cm.current = editor; // Save reference
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip content="Save as code snippet" relationship="label" withArrow>
          <Button
            appearance="subtle"
            icon={<CodeBlockRegular />}
            onClick={() =>
              navigate("/snippets/export", { state: { code: typedCode.replace(`\n${editableLine}`, "") } })
            }
          />
        </Tooltip>
      </div>
      <div style={{ paddingBottom: 150 }}>
        <CodeMirror
          ref={editorRefCallback}
          value={typedCode}
          basicSetup={editorSetup}
          theme={codeDarkTheme}
          minHeight="670px"
          style={{ border: "none" }}
          extensions={[
            python(),
            EditorView.lineWrapping,
            editorStyles,
            Prec.highest(keymap.of(customKeyBindings)),
            //autocompletion({ override: [getAutoCompletions] }),
            readOnlyRangesExtension(getReadOnlyRanges),
          ]}
          onChange={(value) => {
            setTypedCode(value);
          }}
          data-testid="user-editor"
        />
      </div>
      <ActionButtons onCreateClick={handleCreateClick} onRestart={handleRestart} />
    </>
  );
};

export default UserEditor;
