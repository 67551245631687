import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface CountPlotContextType extends BasePlotContext<CountPlot, CountDesign> {
  // TODO: specifics
}

interface CountPlot extends BasePlot {
  // TODO: specifics
}

interface CountDesign extends BaseDesign {
  // TODO: specifics
  marker: string; // This should show the options for the marker "some1" | "some2"
  width: ValueTracker<number>;
  saturation: ValueTracker<number>;
  colorOrder: string;
  dodge: ValueTracker<boolean>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  isCollapsed: boolean;
}

const initialState: CountPlot = {
  plotType: PlotType.count,
  dataRange: undefined,
  orientation: new ValueTracker("Vertical"),
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  hasHeaders: true,
};

const designInitialState: CountDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker("#1f77b4"),
  fillStyle: "One Color",
  palette: new ValueTracker("Accent"),
  marker: "",
  width: new ValueTracker(0.8),
  saturation: new ValueTracker(0.75),
  colorOrder: "",
  dodge: new ValueTracker(true),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  isCollapsed: true,
};

export const CountPlotContext = createContext<CountPlotContextType>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const useCount = () => {
  const context = useContext<CountPlotContextType>(CountPlotContext);
  return context;
};

export const CountPlotProvider = ({ children }: { children: React.ReactNode }) => {
  const [plot, setPlot] = useState<CountPlot>(initialState);
  const [design, setDesign] = useState<CountDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <CountPlotContext.Provider value={{ plot, updatePlot, design, updateDesign, resetContext, updatePlotWithReset }}>
      {children}
    </CountPlotContext.Provider>
  );
};
