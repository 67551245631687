import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import IconHeader, { Introduction } from "../components/Typography";
import { SignInDialog } from "../components/Dialogs/SignInDialog";
import { UpdateRequired } from "../components/Dialogs/UpdateRequired";
import { NavigationList } from "../components/Lists";
import { StartMenuItems } from "../components/Layout/nav-models";
import { Col } from "../components/Layout/Space";
import { Logo } from "../components/Logo";
import { pingForLogin } from "../../auth/broadcast";
import { usePython } from "../hooks/usePython";
import Env, { setManifestVersionFromStartPage, userManifestNeedsUpdate } from "xlcommon/src/environ";
import * as AuthAPI from "../../auth/auth-api";
import "./Start.scss";
import { resetTermsAndConditions } from "./AIAssistant/utils";
function Start() {
  const location = useLocation();
  const { hasPython } = usePython();

  // Manifest must be up-to-date
  const [upToDate, setUpToDate] = useState<boolean>(true);

  // User must login when the add-in first opens
  const [isLoggedIn, setLoggedIn] = useState<boolean>(true); // initial condition to avoid showing login dialog when cross-linked
  const [isAutoLogIn, setIsAutoLogIn] = useState<boolean>(false);
  async function triggerSignIn() {
    if (!Env.signedIn) await AuthAPI.login();
    setLoggedIn(Env.signedIn);
    resetTermsAndConditions();
  }

  useEffect(() => {
    setManifestVersionFromStartPage(location.search);
    (async () => {
      // Check for old version of manifest
      if (!Env.UNLOCKED && userManifestNeedsUpdate()) {
        setUpToDate(false); // show "Update Required" message
        setLoggedIn(true); // to make the sign in dialog go away
        return;
      }

      // Attempt linked-login from PyScript page
      if (!AuthAPI.isLoggedIn()) pingForLogin(triggerSignIn);

      // Check for login status (after delay)
      setTimeout(async () => {
        if (!AuthAPI.isLoggedIn()) {
          // Linked login failed, so show login dialog
          setLoggedIn(false);
          // Attempt auto-login using microsoft token
          setIsAutoLogIn(true);
          await AuthAPI.attemptSilentLogin();
          if (AuthAPI.isLoggedIn()) setLoggedIn(true);
          setIsAutoLogIn(false);
        }
      }, 250);
    })();
  }, []);

  return (
    <div style={{ paddingTop: 8 }}>
      {!upToDate && <UpdateRequired />}
      {!isLoggedIn && (
        <SignInDialog
          title="Sign In Required"
          content="To use Anaconda Toolbox, please sign in to your Anaconda Cloud account."
          defaultOpen={true}
          onContinue={async () => await triggerSignIn()}
          initializing={isAutoLogIn}
        />
      )}
      <Introduction>
        <Logo size={19} />
        Your Toolbox for Python in Excel
      </Introduction>
      <div style={{ marginTop: 32 }} className="start-menu">
        <Col>
          {StartMenuItems.map((heading) => {
            return (
              <div key={heading.title}>
                <IconHeader Icon={<heading.Icon />} text={heading.title} />
                <NavigationList
                  items={heading.items.map((item) => {
                    const disabled = item.disabled || (item.requiresPython && !hasPython && !Env.UNLOCKED);
                    return {
                      text: item.text,
                      to: item.path,
                      disabled: disabled,
                      disabledText: disabled ? "Requires Python in Excel" : "",
                    };
                  })}
                />
              </div>
            );
          })}
        </Col>
      </div>
      {Office.context.platform === Office.PlatformType.Mac && (
        <footer style={{ marginLeft: "8px" }}>
          {" "}
          Looking for Anaconda Code? Click{" "}
          <a href="" onClick={() => (window.location.href = "/pyscript/?version=1.1.0")}>
            here
          </a>
          .
        </footer>
      )}
    </div>
  );
}

export default Start;
