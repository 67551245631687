import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useScatter } from "../../../hooks/plots/useScatter";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import { Axes, Chart, LegendPosition, Markers } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  CollapsibleAttr,
  ColorPickerAttr,
  AxisDropdownAttr,
  CheckBoxAttr,
  SpinnerAttr,
  LegendAttr,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs, CodeBuilder } from "../MVCShared/CodeBuilder";
import { dependencyNotEqualsValue, fetchHeaders, onDataRangeChangeSelection } from "../MVCShared/PlotGeneratorUtils";

const ScatterContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign, updatePlotWithReset } = useScatter();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(scatterPlot, [...scatterPlot.baseAttrs, ...scatterPlot.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const LegendStyleAttr = DropdownAttr({
    label: "Legend Style:",
    value: design.legendStyle,
    options: ["Auto", "Brief", "Full", "False"],
    onChange: (_, e) => updateDesign({ legendStyle: e.optionValue }),
    codeKey: "legend",
    codeRequiresInteraction: true,
    dataTestID: "legend",
  });

  LegendStyleAttr.getCode = (code: CodeBuilder) => {
    if (design.legendStyle.isUpdated) {
      design.legendStyle.value === "False"
        ? code.plotAttrs.push("legend=False")
        : code.plotAttrs.push(`legend="${design.legendStyle.value.toLowerCase()}"`);
    }
  };

  const MarkerAttr = DropdownAttr({
    label: "Marker:",
    value: design.marker,
    options: ["Point", "Circle", "Plus", "Star", "Diamond", "X", "None"],
    onChange: (_, e) => updateDesign({ marker: e.optionValue }),
    codeKey: "marker",
    codeRequiresInteraction: true,
    dataTestID: "marker",
  });

  MarkerAttr.getCode = (code: CodeBuilder) => {
    if (design.marker.isUpdated) {
      code.plotAttrs.push(`marker="${Markers[design.marker.value]}"`);
    }
  };

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position:",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const scatterPlot: Chart = {
    plotType: PlotType.scatter,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) =>
          onDataRangeChangeSelection(newSelection, updatePlotWithReset),
      }),
      CheckBoxAttr({
        label: "Data Has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      AxisDropdownAttr({
        value: plot.xAxis,
        onChange: (_, data) => {
          updatePlot({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        label: "X-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "x",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.yAxis,
        onChange: (_, data) => {
          updatePlot({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "y",
        hasHeaders: plot.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Grouping", tooltip: "Select parameters to determine point color, size, and style" }),
      AxisDropdownAttr({
        value: plot.colorBy,
        onChange: (_, data) => updatePlot({ colorBy: data.optionValue }, "--None--"),
        label: "Color By:",
        options: ["--None--", ...plot.headers],
        placeholder: "--None--",
        codeKey: "hue",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.sizeBy,
        onChange: (_, data) => updatePlot({ sizeBy: data.optionValue }, "--None--"),
        label: "Size By:",
        options: ["--None--", ...plot.headers],
        placeholder: "--None--",
        codeKey: "size",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.styleBy,
        onChange: (_, data) => updatePlot({ styleBy: data.optionValue }, "--None--"),
        label: "Style By:",
        options: ["--None--", ...plot.headers],
        placeholder: "--None--",
        codeKey: "style",
        hasHeaders: plot.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Output", tooltip: "Determine where visualization will render" }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: plot.isCollapsed,
        toggle: () => updatePlot({ isCollapsed: !plot.isCollapsed }),
        children: [
          HeadingAttr({
            title: "Axes",
            tooltip: "Set scale for axes",
          }),
          DropdownAttr({
            value: plot.newXAxisScale,
            onChange: (_, data) => updatePlot({ newXAxisScale: data.optionValue }),
            label: "X-Axis Scale:",
            options: ["Linear", "Log"],
            callKey: "xscale",
            codeValueMap: Axes,
            codeRequiresInteraction: true,
            dataTestID: "x-axis-scale",
          }),
          DropdownAttr({
            value: plot.newYAxisScale,
            onChange: (_, data) => updatePlot({ newYAxisScale: data.optionValue }),
            label: "Y-Axis Scale:",
            options: ["Linear", "Log"],
            callKey: "yscale",
            codeValueMap: Axes,
            codeRequiresInteraction: true,
            dataTestID: "y-axis-scale",
          }),
        ],
      }),
    ],
    designAttrs: [
      HeadingAttr({ title: "Chart Info" }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "X-Axis Label:",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "Y-Axis Label:",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks:",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks:",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      ColorPickerAttr({
        value: design.fill,
        label: "Fill:",
        onChange: (hexColor) => updateDesign({ fill: `#${hexColor}` }),
        codeKey: "color",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Markers" }),
      MarkerAttr,
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      LegendStyleAttr,
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top:",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right:",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
        ],
      }),
    ],
  };
  return scatterPlot;
};

const ScatterForm = () => {
  const scatterPlot = ScatterContext();
  return buildReactFromAttrs(scatterPlot.baseAttrs);
};

export const ScatterDesign = () => {
  const scatterDesign = ScatterContext();
  return buildReactFromAttrs(scatterDesign.designAttrs);
};

export default ScatterForm;
