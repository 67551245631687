import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

interface LinePlotContextType extends BasePlotContext<LinePlot, LineDesign> {}

interface LinePlot extends BasePlot {
  styleMarkersBy?: string;
  styleLinesBy?: string;
  sizeByLines?: string;
  primaryInterval: number;
  secondInterval: number;
  bootstrapSamples: ValueTracker<number>;
  seed: ValueTracker<number>;
  sort: ValueTracker<boolean>;
  estimator?: string;
  errorBar?: string;
  isCollapsed: boolean;
}

interface LineDesign extends BaseDesign {
  // TODO: specifics
  marker: ValueTracker<string>; // This should show the options for the marker "some1" | "some2"
  lineStyle: ValueTracker<string>;
  errorStyle: string;
  legendStyle: ValueTracker<string>;
  lineWidth: ValueTracker<number>;
  errorBarColor: ValueTracker<string>;
  errorBarWidth: ValueTracker<number>;
  errorBarCapWidth: ValueTracker<number>;
  isDesignCollapsed: boolean;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
}

const initialState: LinePlot = {
  plotType: PlotType.line,
  dataRange: undefined,
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  xAxisScale: "",
  yAxisScale: "",
  errorBar: "--None--",
  primaryInterval: 95,
  secondInterval: 1,
  bootstrapSamples: new ValueTracker(1000),
  seed: new ValueTracker(0),
  sort: new ValueTracker(true),
  isCollapsed: true,
  styleMarkersBy: "",
  styleLinesBy: "",
  sizeByLines: "",
  hasHeaders: true,
};

const designInitialState: LineDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker("#1f77b4"),
  fillStyle: "",
  marker: new ValueTracker("None"),
  palette: new ValueTracker("Tab10"),
  errorStyle: "",
  lineStyle: new ValueTracker("Solid"),
  lineWidth: new ValueTracker(3),
  legendStyle: new ValueTracker("Auto"),
  errorBarColor: new ValueTracker("#000000"),
  errorBarWidth: new ValueTracker(2),
  errorBarCapWidth: new ValueTracker(0.5),
  isDesignCollapsed: true,
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
};

export const LinePlotContext = createContext<LinePlotContextType>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const useLine = () => {
  const context = useContext<LinePlotContextType>(LinePlotContext);
  return context;
};

export const LinePlotProvider = ({ children }: { children: React.ReactNode }) => {
  const [plot, setPlot] = useState<LinePlot>(initialState);
  const [design, setDesign] = useState<LineDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <LinePlotContext.Provider
      value={{
        plot,
        updatePlot,
        design,
        updateDesign,
        resetContext,
        updatePlotWithReset,
      }}
    >
      {children}
    </LinePlotContext.Provider>
  );
};
