import React, { useCallback, useState } from "react";
import { ColorPicker, getColorFromString, IColor, IColorPickerStyles, mergeStyleSets } from "@fluentui/react";
import { Row } from "../../../components/Layout/Space";
import "../Viz.scss";

const green = getColorFromString("#0cca4a")!;

interface ColorPickerProps {
  onChange: (hexColor, rgbaColor) => void;
  label?: string;
  defaultColor?: string;
}

export const VizColorPicker: React.FC<ColorPickerProps> = ({ label, onChange, defaultColor }) => {
  const initialColor = defaultColor ? getColorFromString(defaultColor)! : green;
  const [color, setColor] = useState(initialColor);
  const [collapsed, setCollapsed] = useState(true);

  const updateColor = useCallback((_: any, colorObj: IColor) => setColor(colorObj), []);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <Row justifyContent="space-between" gap={10} alignItems="center">
        <label className="label">{label}</label>
        <div className={classNames.wrapper}>
          <div className={classNames.column1}>
            <div className={classNames.colorBox} style={{ backgroundColor: color.str }} onClick={toggleCollapse}></div>
            {!collapsed && (
              <>
                <ColorPicker
                  color={color}
                  onChange={(ev, updatedColor) => {
                    updateColor(ev, updatedColor);
                    onChange(updatedColor.hex, updatedColor);
                  }}
                  alphaType="alpha"
                  showPreview={true}
                  styles={colorPickerStyles}
                  strings={{
                    hueAriaLabel: "Hue",
                  }}
                />
              </>
            )}
          </div>
          <div className={classNames.column2}></div>
        </div>
      </Row>
    </div>
  );
};

const classNames = mergeStyleSets({
  wrapper: { display: "flex", alignItems: "center" },
  column1: { marginRight: 10 },
  column2: { marginLeft: 10 },
  colorBox: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    border: "none",
    transition: "all 0.3s ease-in-out",
  },
});

const colorPickerStyles: Partial<IColorPickerStyles> = {
  panel: { padding: 12 },
  root: {
    maxWidth: 250,
    minWidth: 250,
  },
  colorRectangle: { height: 200 },
};

export default VizColorPicker;
