import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CloudArrowUp20Regular } from "@fluentui/react-icons";
import { Button, Field, Input, Spinner, Text } from "@fluentui/react-components";
import { ActionButtonRow, Col } from "../../../components/Layout/Space";
import TableChooser from "../../../../excel/components/TableChooser";
import ProjectChooser from "../../../../data/components/ProjectChooser";
import { PrimitivePreviewTable } from "../../../components/PreviewTable";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import ProjectsService from "../../../../data/projects/projects-api";
import { uploadFileFromSelection, getTypedContentFromSelection } from "../../../../data/data-api-upload";
import { loadProjectDefinitions, saveProjectDefinitions } from "../../../../data/data-api";
import { useProjects } from "../../../../queryclient/projects/projects";
import { Project } from "../../../../data/projects/models";
import { CloudProjectFileDefinition, Direction, VERSION } from "../../../../data/models";
import { capitalize, ensureFileSuffix } from "../../../utils";
import { Mode } from "../../../types";
import { snakeEyesRecord } from "../../../../analytics/snake-eyes";
import { FileTooLargeError } from "../../../../data/projects/projects-api";
import "./ConnectToWorkbook.scss";

const PREVIEW_NUM_ROWS = 6;
const PREVIEW_NUM_COLS = 50;

const initialFileDefinition = {
  fileName: "",
  sourceSelection: {} as RangeSelection,
  direction: Direction.Upload,
};

export enum ExportResolution {
  Upload = "upload",
  NewVersion = "newVersion",
  ReUpload = "reUpload",
  OverwriteCloudVersion = "overwriteCloudVersion",
  NewFilename = "newFilename",
  RestoreCloudData = "restoreCloudData",
}

function getButtonText(resolution: ExportResolution): string {
  switch (resolution) {
    case ExportResolution.Upload:
      return "Connect to Cloud";
    case ExportResolution.NewVersion:
      return "Upload New Version";
    case ExportResolution.ReUpload:
      return "Re-upload to Cloud";
    case ExportResolution.OverwriteCloudVersion:
      return "Overwrite Cloud Version";
    case ExportResolution.NewFilename:
      return "Connect New Filename";
  }
}

async function computeNameOfCopy(projectId: string, fileName: string): Promise<string> {
  const projectFiles = await ProjectsService.getProjectFiles(projectId);
  const names = new Set(projectFiles.items.map((x) => x.name));
  let newName = `Copy of ${fileName}`;
  let i = 1;
  while (names.has(newName)) {
    i += 1;
    newName = `Copy${i} of ${fileName}`;
  }
  return newName;
}

export type DataExportProps = {
  selectedRange: RangeSelection;
  project?: Project;
  fileName?: string;
  resolution?: ExportResolution;
};

function ConnectToCloud() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    selectedRange: initialSelectedRange,
    project: initialProject = { name: "" },
    fileName: initialFileName = "",
    resolution = ExportResolution.Upload,
  }: DataExportProps = location?.state || {};

  const [project, setProject] = useState<Project>(initialProject);
  const [isWriteable, setWriteable] = useState<boolean>(false);
  const [fileDefinition, setFileDefinition] = useState<CloudProjectFileDefinition>({ ...initialFileDefinition });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [fileLimitError, setFileLimitError] = useState<string>(null);

  const { refetch: refetchProjects } = useProjects({ tag: "data" });

  async function upload() {
    setIsUploading(true);
    try {
      const lastModified = await uploadFileFromSelection(
        fileDefinition.sourceSelection,
        project.id,
        fileDefinition.fileName
      );

      await saveUploadDefinition(lastModified);
      snakeEyesRecord({
        event: "data_connectors/project_upload",
      });

      await refetchProjects();

      navigate("/data", {
        state: {
          tab: "connections",
        },
      });
    } catch (e) {
      setErrorMessage("Your selection exceeds Excel limits. Please reduce the size of your selection and try again.");
      if (e instanceof FileTooLargeError) {
        setFileLimitError("Upload cannot exceed 25MB");
      }
    }
    setIsUploading(false);
  }

  async function saveUploadDefinition(lastModified: string): Promise<void> {
    try {
      // Load current project definition
      const projectDefinitions = await loadProjectDefinitions();
      fileDefinition.fileName = ensureFileSuffix(fileDefinition.fileName, ".csv");

      if (projectDefinitions.projects[project.id] && projectDefinitions.projects[project.id].files) {
        // Handle a special resolution mode for uploading a new filename
        // The connection with the old filename is intended to be removed
        if (resolution === ExportResolution.NewFilename) {
          delete projectDefinitions.projects[project.id].files[initialFileName];
        }
        // We have a definition already, so update its file definition
        // The API ensures uniqueness of filenames per project
        projectDefinitions.projects[project.id].files[fileDefinition.fileName] = {
          ...fileDefinition,
          lastUploaded: lastModified,
        };
      } else {
        // We need to make a new definition for this project
        console.log("Creating new project definition for ", project.id);
        projectDefinitions.projects[project.id] = {
          id: project.id,
          title: project.title,
          name: project.name,
          files: {
            // The API ensures uniqueness of filenames per project
            [fileDefinition.fileName]: {
              ...fileDefinition,
              lastUploaded: lastModified,
            },
          },
        };
      }
      // Save Excel definition
      console.log({
        projects: projectDefinitions.projects,
        version: VERSION,
      });
      await saveProjectDefinitions({
        ...projectDefinitions,
        version: VERSION,
      });
    } catch (e) {
      console.log(`Error: Unable to save data upload definition.\n${e}`);
    }
  }

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      let fileName = initialFileName;
      if (resolution === ExportResolution.NewFilename) {
        // Generate new filename, while keeping the original as initialFileName
        fileName = await computeNameOfCopy(initialProject.id, initialFileName);
      }
      const savedFileDef = {
        fileName: fileName,
        sourceSelection: { ...initialSelectedRange },
        direction: Direction.Upload,
      };
      setFileDefinition({ ...savedFileDef });
      setPreviewContent({ ...savedFileDef });
      setIsFetching(false);
    })();
  }, []);

  const [preview, setPreview] = useState<{
    title: string;
    columns: string[];
    content: string[][];
  }>({
    title: "",
    columns: [],
    content: [],
  });

  const [errorMessage, setErrorMessage] = useState<string>();
  // Get data from Excel to preview in the preview table
  async function setPreviewContent(fileDefinition: CloudProjectFileDefinition) {
    const { sourceSelection, fileName }: CloudProjectFileDefinition = fileDefinition;
    let content;
    try {
      content = await getTypedContentFromSelection(sourceSelection, PREVIEW_NUM_ROWS, PREVIEW_NUM_COLS);
      if (content?.length > 0) {
        setPreview({ title: fileName, columns: content[0], content: content });
      }
    } catch (e) {
      if (sourceSelection?.identifier) {
        setErrorMessage("Your selection exceeds Excel limits. Please reduce the size of your selection and try again.");
      }
      console.log(e);
    }
  }

  async function onProjectSelection(value: Project, isWriteable: boolean) {
    setProject({ ...value });
    setWriteable(isWriteable);
  }

  function isReadyForUpload() {
    if (project.title === "") return false;
    if (fileLimitError) return false;
    if (!isWriteable) return false;
    if (!fileDefinition.fileName || !fileDefinition.sourceSelection?.identifier) return false;
    return true;
  }

  return (
    <div className="tab-content connections">
      <Col gap={12}>
        <Text weight="bold" size={400}>{`${capitalize(
          resolution !== ExportResolution.Upload ? Mode.Edit : Mode.New
        )} Dataset`}</Text>
        <Field label="Data to Connect" validationMessage={fileLimitError}>
          <Col>
            <Col key={fileDefinition.sourceSelection?.displayName} gap={6}>
              <TableChooser
                key={fileDefinition.sourceSelection?.identifier}
                dataTestId="dataUploadChooseSource"
                selection={fileDefinition.sourceSelection}
                onSelection={(newSelection) => {
                  const newFileDefinition = { ...fileDefinition, sourceSelection: { ...newSelection } };
                  setFileDefinition({ ...newFileDefinition });
                  setPreviewContent({ ...newFileDefinition });
                  setFileLimitError("");
                  setErrorMessage(undefined);
                }}
              />
            </Col>
          </Col>
        </Field>
        <ProjectChooser
          tag="data"
          label="Project"
          selection={project}
          onSelection={onProjectSelection}
          dataTestId="dataUploadProjectChooser"
        />
        <Field
          style={{ position: "relative" }}
          label=""
          // validationMessage
        >
          <div>
            <Field label="Dataset Title">
              <Input
                style={{ width: "100%", height: 38 }}
                className="upload-input"
                onChange={(_, data) => setFileDefinition({ ...fileDefinition, fileName: data.value })}
                value={fileDefinition.fileName}
                // The "title" is the file name
                placeholder="Enter title..."
                disabled={isFetching}
                data-testid="dataUploadFileName"
              />
            </Field>
          </div>
        </Field>
        <PrimitivePreviewTable title={preview.title} columns={preview.columns} items={preview.content} />
      </Col>
      <ActionButtonRow justifyContent="flex-end">
        <Field style={{ width: "100%" }} validationMessage={errorMessage} validationState="error">
          <Button
            className={`action-button ${isUploading ? "saving" : "primary"}`}
            style={{ padding: "8px 12px", width: "100%" }}
            appearance="primary"
            disabled={!isReadyForUpload()}
            iconPosition="after"
            icon={!isUploading ? <CloudArrowUp20Regular /> : null}
            onClick={() => upload()}
            data-testid="dataUploadPerformUpload"
          >
            {isUploading ? <Spinner size="tiny" /> : getButtonText(resolution)}
          </Button>
        </Field>
      </ActionButtonRow>
    </div>
  );
}

export default ConnectToCloud;
