import React from "react";
import { Checkbox } from "@fluentui/react-components";
import { CodeBuilder } from "../MVCShared/CodeBuilder";
import { ChartAttr, ValueTracker } from "../MVCShared/types";
import { Row } from "../../../components/Layout/Space";

type FacetSetup = {
  sharexVal: boolean | ValueTracker<boolean>;
  shareyVal: boolean | ValueTracker<boolean>;
  facetPalette: string | ValueTracker<string>;
  xlim: number | ValueTracker<number>;
  ylim: number | ValueTracker<number>;
  codeKey: string;
  onChangeSharex: (_, value) => void;
  onChangeSharey: (_, value) => void;
  codeRequiresInteraction?: boolean;
};
export function Facet(args: FacetSetup): ChartAttr {
  const {
    codeKey,
    sharexVal,
    shareyVal,
    facetPalette,
    xlim,
    ylim,
    onChangeSharex,
    onChangeSharey,
    codeRequiresInteraction,
  } = args;
  let shareXVal = sharexVal instanceof ValueTracker ? sharexVal.value : sharexVal;
  let shareYVal = shareyVal instanceof ValueTracker ? shareyVal.value : shareyVal;

  return {
    component: "Facet Grid",
    getReact: () => {
      return (
        <>
          <Row justifyContent="space-between" gap={5}>
            <label className="label">Share X-Axis:</label>
            <Checkbox checked={shareXVal} onChange={onChangeSharex} data-testid="sharex" />
          </Row>
          <Row justifyContent="space-between" gap={5}>
            <label className="label">Share Y-Axis:</label>
            <Checkbox checked={shareYVal} onChange={onChangeSharey} data-testid="sharey" />
          </Row>
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      let start = `${codeKey}=dict(`;
      let anyUpdated = false;

      if (codeRequiresInteraction) {
        let sxval: string = shareXVal ? "True" : "False";
        let syval: string = shareYVal ? "True" : "False";

        if (sharexVal instanceof ValueTracker && sharexVal.isUpdated) {
          start += `sharex=${sxval}, `;
          anyUpdated = true;
        }
        if (shareyVal instanceof ValueTracker && shareyVal.isUpdated) {
          start += `sharey=${syval}, `;
          anyUpdated = true;
        }
        if (facetPalette instanceof ValueTracker && facetPalette.isUpdated) {
          start += `palette='${facetPalette.value}', `;
          anyUpdated = true;
        }

        if (xlim instanceof ValueTracker && xlim.isUpdated) {
          start += `xlim=(0, ${xlim.value}), `;
          anyUpdated = true;
        }

        if (ylim instanceof ValueTracker && ylim.isUpdated) {
          start += `ylim=(0, ${ylim.value}), `;
          anyUpdated = true;
        }
      }
      if (anyUpdated) {
        start = start.slice(0, -2);
        start += `)`;
        code.plotAttrs.push(start);
      }
    },
  };
}
