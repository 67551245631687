import React from "react";
import { ChevronRightFilled } from "@fluentui/react-icons";
import { Body1Stronger, Card, MessageBarBody } from "@fluentui/react-components";

const WarningMessageBlock = ({ settingsLink = false }: { settingsLink: boolean }) => {
  return (
    <Card style={{ boxShadow: "unset", border: "1px solid #17A4BA", backgroundColor: "#0B819314" }}>
      <MessageBarBody>
        {`This dataset exceeds Excel's row limit. To import the full dataset, filter the dataset or import it as a
        PyScript object.`}
        {settingsLink && (
          <>
            <br />
            <br />
            {`This requires selecting `}
            <Body1Stronger>Generate Toolbox Code As =PYSCRIPT.CODE() </Body1Stronger>under Anaconda Toolbox{" "}
            <ChevronRightFilled style={{ verticalAlign: "middle" }} /> Settings.
          </>
        )}
      </MessageBarBody>
    </Card>
  );
};

export default WarningMessageBlock;
