import React, { useState, useContext, useEffect, Suspense } from "react";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { Spinner } from "@fluentui/react-components";
import { Row } from "../../components/Layout/Space";
import { useGeneratedCodeContext } from "../../hooks/useCode";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import ActionButtons from "./ActionButtons";
import { Context } from "./Code";
import { editableLine } from "./UserEditor";
import { buildChartPreview } from "./viz-preview";
import Trace from "./Trace";

const Preview = () => {
  const { generatedCode, clearGeneratedCode, typedCode } = useGeneratedCodeContext();
  const [previewLoading, setPreviewLoading] = useState<boolean>();
  const [typedCodePreview, setTypedCodePreview] = useState<string>();
  const { plotType } = useParams();
  const contextType = Context[plotType];
  const { plot, resetContext } = useContext<typeof contextType>(contextType);

  const handleRestart = async () => {
    await resetContext();
    clearGeneratedCode();
  };
  const handleCreateClick = async () => {
    const cleanedTypedCode = typedCode.replace(editableLine, "");
    const addr = await getFullAddressFromRangeSelection(plot.outputCell);
    await writeToExecutionLocation(addr, cleanedTypedCode);
  };

  const [trace, setTrace] = useState<string>();
  useEffect(() => {
    if (generatedCode?.source) {
      (async () => {
        try {
          setPreviewLoading(true);

          const startIndex = typedCode?.indexOf(`''' Editable beyond this line '''`);
          const pyodideExcelCode = typedCode.substring(startIndex);

          const sourceAddr = await getFullAddressFromRangeSelection(generatedCode.source);
          if (!sourceAddr) return;

          const dataFrame = plot.hasHeaders ? "data[1:], columns=data[0]" : "data";
          await buildChartPreview(pyodideExcelCode, dataFrame, sourceAddr).then((result) => {
            const hasTrace = result.indexOf("<img") === -1;
            if (!hasTrace) {
              setTypedCodePreview(result);
            } else {
              setTrace(result);
            }
            setPreviewLoading(false);
          });
        } catch (e) {
          setPreviewLoading(false);
        }
      })();
    }
  }, [generatedCode, plot.hasHeaders]);

  if (!generatedCode?.source) {
    return <p>Configure your visualization in the setup tab to preview chart.</p>;
  }

  return (
    <Suspense>
      {previewLoading && (
        <Row justifyContent="center" gap={15}>
          <div style={{ margin: "10px 0" }}>
            <Spinner size="tiny" label="Loading plot preview for selected data range..." labelPosition="after" />
          </div>
        </Row>
      )}
      {trace && <Trace content={trace} />}
      {typedCode && (
        <Row>
          <div className="plot-preview" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(typedCodePreview) }} />
        </Row>
      )}
      <ActionButtons onCreateClick={handleCreateClick} onRestart={handleRestart} />
    </Suspense>
  );
};

export default Preview;
