import React from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { CustomDropdown } from "./VizUIShared";

const chartOptions: IDropdownOption[] = [
  { key: "bar", text: "Bar", data: { img: "/static/assets/bar-clustered-black.svg" } },
  { key: "box", text: "Box", data: { img: "/static/assets/box-black.svg" } },
  { key: "count", text: "Count", data: { img: "/static/assets/count-black.svg" } },
  { key: "distribution", text: "Distribution", data: { img: "/static/assets/displot.svg" } },
  { key: "line", text: "Line", data: { img: "/static/assets/line-black.svg" } },
  { key: "pairwise", text: "Pairwise", data: { img: "/static/assets/pairplot.svg" } },
  { key: "regression", text: "Regression", data: { img: "/static/assets/regression-black.svg" } },
  { key: "scatter", text: "Scatter", data: { img: "/static/assets/scatter-black.svg" } },
  { key: "violin", text: "Violin", data: { img: "/static/assets/violin-black.svg" } },
].map(({ key, text, data }) => ({
  key,
  text,
  data: {
    component: (
      <>
        <img src={data.img} alt={text} style={{ width: 14, height: 14 }} />
        {text}
      </>
    ),
  },
}));

type ChartOptionsProps<T> = {
  value: string;
  onChange?: (e, data) => void;
  options?: T[];
};

const ChartDropdown: React.FC<ChartOptionsProps<string>> = ({ onChange, value }) => {
  function handleChange(e, value: string) {
    const plotType = value.split(" ")[0].toLowerCase();
    if (plotType) {
      onChange(e, plotType);
    }
  }

  return (
    <CustomDropdown
      label="Chart Type:"
      options={chartOptions}
      value={value.charAt(0).toUpperCase() + value.slice(1)}
      onChange={(_, item) => handleChange(_, item.optionText)}
    />
  );
};

export default ChartDropdown;
