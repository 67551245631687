import {
  AssistantChatSummary,
  AssistantStore,
  ChatStarter,
  Header,
  HistoryPage,
  INucleusUser,
  TermsAndConditionsPage,
  getActiveChat,
  getActiveNotebookChats,
  useStore,
} from "@anaconda/assistant-components";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import Env from "xlcommon/src/environ";
import "./AIAssistant.scss";
import { ChatPanel } from "./ChatPanel";
import { ErrorScreen } from "./ErrorScreen";
import { SettingsWrapper } from "./SettingsWrapper";
import { TableRangeChooserPage } from "./TableRangeChooserPage";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  getAliasFromRangeSelection,
  RangeSelection,
  RangeType,
  getSplitIdAddrFromBinding,
} from "xlcommon/src/excel/excel-grid-utils";
import { getPythonLocation, writeToExecutionLocation } from "../../../excel/grid-utils";

import { TOKEN_LIMIT } from "./config";

import { initAssistantStore } from "@anaconda/assistant-components";
import { AssistantEnvironmentType } from "@anaconda/assistant-sdk";
import { useAccount } from "../../../queryclient/account/account";
import { getAssistantSdk, initAssistantSdk } from "./utils/assistantSdk";
import { initializeAssistant } from "./utils/initializeAssistant";
import { snakeEyesRecord } from "../../../analytics/snake-eyes";
import { PythonExecutionLocation } from "../../../excel/types";

const DELETED_CHAT_KEYS: string[] = [];

export function AIAssistant() {
  const [assistantStore, setAssistantStore] = React.useState<AssistantStore | null>(null);

  const envType = Env.ASSISTANT_CLIENT_SOURCE.replace("anaconda-", "") as AssistantEnvironmentType;

  const { data: account } = useAccount();

  const initAiAssistant = async () => {
    await initAssistantSdk({
      clientVersion: `${Env.MANIFEST_VERSION_IN_STORE}`,
      apiUrl: `${Env.BASE_URL}`,
      environmentType: `${envType}`,
      enableDataCollection: false,
      routePathPrefix: `${Env.ASSISTANT_ROUTE_PATH_PREFIX}`,
    });

    const assistantStoreLoaded = await initAssistantStore({
      getAssistantSdk,
      // No need to fetch the server URL as it is already provided in the manifest
      getServerUrl: () => Promise.resolve(""),
      getNucleusAccount: () => Promise.resolve(account as unknown as INucleusUser), // this is require to transform the account object to INucleusUser
      getAccountMaxTokens: () => Promise.resolve(TOKEN_LIMIT),
      // There is no flag identifying local dev environment
      isLocalDev: () => false,
      // No need to sync with disk
      syncDiskStateChained: () => Promise.resolve(),
      unbindSyncDiskStateChained: () => {},
      deletedChatKeys: DELETED_CHAT_KEYS,
      clientContextType: "sheet",
    });

    setAssistantStore(assistantStoreLoaded);
    await initializeAssistant();
  };

  React.useEffect(() => {
    initAiAssistant();
  }, [account]);

  return account && assistantStore ? (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <InnerPanel />
    </ErrorBoundary>
  ) : null;
}

function InnerPanel() {
  const navigate = useNavigate();
  const [olderThan13, setOlderThan13] = React.useState(false);
  const [selection, setSelection] = React.useState<RangeSelection | null>(null);
  const [tableChooserContext, setTableChooserContext] = React.useState<ChatStarter | null>(null);
  const [
    accepted,
    acceptTerms,
    enableDataCollection,
    setEnableDataCollection,
    toggleHistoryOpen,
    resetToHomeScreen,
    toggleSettings,
    chats,
    activeChat,
    setActiveChat,
    deleteChat,
    makeChatRequest,
  ] = useStore((state) => [
    state.terms.accepted,
    state.acceptTerms,
    state.settings.enableDataCollection,
    state.setEnableDataCollection,
    state.toggleMenuOpen,
    state.resetToHomeScreen,
    state.toggleSettings,
    getActiveNotebookChats(state),
    getActiveChat(state),
    state.setActiveChat,
    state.deleteChat,
    state.makeChatRequest,
  ]);

  const stateCode = React.useRef("");

  async function addCodeToContainer(code: string) {
    stateCode.current = code;
    const options = { promptText: "Click to select" };
    Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, options, promptCallback);
  }

  async function promptCallback(result) {
    if (result.value === undefined) {
      console.log("User cancelled the dialog");
      return;
    }

    let code = stateCode.current;

    if (selection) {
      // Define _df at the top of the code
      let alias = await getAliasFromRangeSelection(selection);
      if (alias === undefined && selection.displayName) {
        alias = selection.displayName;
      }
      const executionLocation: PythonExecutionLocation = await getPythonLocation();
      if (executionLocation === PythonExecutionLocation.Local) {
        code = `_data = REF("${alias.replaceAll(
          '"',
          '\\"'
        )}")\n_df = pd.DataFrame(_data[1:], columns=_data[0])\n\n${code}`;
      } else {
        code = `_df = xl("${alias.replaceAll('"', '\\"')}", headers=True)\n\n${code}`;
      }
    }

    // Convert chosen target cell to an address
    const targetAddress = await getAliasFromRangeSelection({
      rangeType: RangeType.CellBinding,
      identifier: result.value.id,
    });
    // Easy way to delete the binding id as a side effect
    await getSplitIdAddrFromBinding(result.value.id, /*deleteId=*/ true);

    await writeToExecutionLocation(targetAddress, code);
    snakeEyesRecord({
      event: "assistant/chat_sent_to_grid",
    });
  }

  const handleActivateTableChooser = (chatStarter: ChatStarter) => {
    setTableChooserContext(chatStarter);
    navigate("/ai/data-chooser");
  };

  const handleNavigation = (page: string) => {
    switch (page) {
      case "settings":
        navigate("/ai/settings");
        toggleSettings();
        break;
      case "history":
        navigate("/ai/history");
        toggleHistoryOpen();
        break;
      case "home":
      default:
        navigate("/ai");
        resetToHomeScreen();
        break;
    }

    snakeEyesRecord({
      event: "assistant/page_switched",
    });
  };

  const historyChats = (chats: AssistantChatSummary[]) => {
    const modifiedHistoryChats = chats.map((chat) => {
      // TODO: When new contexts are added which accept tables and ranges, include them here
      const regex = /^(?:Aggregate or summarize |Generate graphs from ).*\(using no context from Sheet\)$/;
      const name = regex.test(chat.name) ? chat.name.replace("(using no context from Sheet)", "") : chat.name;
      return { ...chat, name };
    });
    return modifiedHistoryChats;
  };

  React.useEffect(() => {
    if (activeChat?.context?.variables["range_selection"]) {
      const rangeSelection = JSON.parse(activeChat?.context?.variables["range_selection"]);
      setSelection(rangeSelection);
    } else {
      setSelection(null);
    }
  }, [activeChat]);

  return (
    <div className="as-container">
      <Header
        disableMenu={!accepted}
        activeChat={!!activeChat}
        clientVersion={""}
        chatsHistoryOpen={useLocation().pathname === "/ai/history"}
        showSettings={true}
        settingsOpen={useLocation().pathname === "/ai/settings"}
        toggleChatsHistory={() => handleNavigation("history")}
        newChatClicked={() => handleNavigation("home")}
        toggleSettings={() => handleNavigation("settings")}
      />
      {!accepted ? (
        <TermsAndConditionsPage
          accept={async () => {
            let version = useStore.getState().terms.version;
            if (!version) {
              try {
                version = await getAssistantSdk().fetchTermsAndConditionsVersion();
              } catch (e) {
                console.error("Failed to fetch terms version");
                return;
              }
            }
            acceptTerms(version, true);
          }}
          enableDataCollection={enableDataCollection}
          setEnableDataCollection={setEnableDataCollection}
          olderThan13={olderThan13}
          setOlderThan13={setOlderThan13}
        />
      ) : (
        <Routes>
          <Route path="/settings" element={<SettingsWrapper />} />
          <Route
            path="/history"
            element={
              <HistoryPage
                chats={historyChats(chats)}
                activeChat={activeChat}
                setActiveChat={setActiveChat}
                deleteChat={deleteChat}
                closeHistory={() => navigate("/ai")}
              />
            }
          />
          <Route
            path="/data-chooser"
            element={
              <TableRangeChooserPage
                rangeState={null} // Selection resets each time it is used
                setRangeState={setSelection}
                chatStarter={tableChooserContext}
                makeChatRequest={makeChatRequest}
                activeChatKey={activeChat?.key}
                closeTableChooser={() => navigate("/ai")}
              />
            }
          />
          <Route
            path="/"
            element={
              <ChatPanel addCodeToContainer={addCodeToContainer} activateTableChooser={handleActivateTableChooser} />
            }
          />
        </Routes>
      )}
    </div>
  );
}
