import React from "react";
import { FocusTrapZone, Layer, Overlay, Popup } from "@fluentui/react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogContent,
  DialogActions,
  Button,
} from "@fluentui/react-components";

interface PopUpProps {
  onHandleContinue: () => void;
  dialogContent: string;
  isPopUpVisible: boolean;
  hidePopUp: () => void;
  dialogTitle: string;
}
const ContinuePopUp = ({ onHandleContinue, dialogContent, isPopUpVisible, hidePopUp, dialogTitle }: PopUpProps) => {
  return (
    <>
      {isPopUpVisible && (
        <Layer>
          <Overlay className="action-root" onClick={hidePopUp} />
          <FocusTrapZone>
            <Popup className="action-root" onDismiss={hidePopUp} aria-label="closeButton">
              <Dialog modalType="non-modal" open={isPopUpVisible} onOpenChange={hidePopUp}>
                <DialogSurface className="custom-dialog-surface">
                  <DialogBody>
                    <DialogTitle style={{ fontSize: "14px", color: "#2C303A" }}>{dialogTitle}</DialogTitle>
                    <DialogContent>{dialogContent}</DialogContent>
                    <DialogActions>
                      <Button data-testid="cancel-button" onClick={hidePopUp}>
                        Cancel
                      </Button>
                      <Button
                        data-testid="continue-button"
                        onClick={onHandleContinue}
                        style={{ backgroundColor: "#D00000", color: "#FFFFFF" }}
                        appearance="primary"
                      >
                        Continue
                      </Button>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            </Popup>
          </FocusTrapZone>
        </Layer>
      )}
    </>
  );
};
export default ContinuePopUp;
