import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface ScatterPlotContextType extends BasePlotContext<ScatterPlot, ScatterDesign> {
  // TODO: specifics
}

interface ScatterPlot extends BasePlot {
  // TODO: specifics
  sizeBy: string;
  styleBy: string;
  newXAxisScale: ValueTracker<string>;
  newYAxisScale: ValueTracker<string>;
  isCollapsed: boolean;
}

interface ScatterDesign extends BaseDesign {
  // TODO: specifics
  marker: ValueTracker<string>;
  legendStyle: ValueTracker<string>;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  isCollapsed: boolean;
}

const initialState: ScatterPlot = {
  plotType: PlotType.scatter,
  dataRange: undefined,
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  sizeBy: "",
  styleBy: "",
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  newXAxisScale: new ValueTracker("Linear"),
  newYAxisScale: new ValueTracker("Linear"),
  isCollapsed: true,
  hasHeaders: true,
};

const designInitialState: ScatterDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker("#1f77b4"),
  marker: new ValueTracker("Point"),
  legendStyle: new ValueTracker("Auto"),
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
};

export const ScatterPlotContext = createContext<ScatterPlotContextType>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const useScatter = () => {
  const context = useContext<ScatterPlotContextType>(ScatterPlotContext);
  return context;
};

export const ScatterPlotProvider = ({ children }: { children: React.ReactNode }) => {
  const [plot, setPlot] = useState<ScatterPlot>(initialState);
  const [design, setDesign] = useState<ScatterDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <ScatterPlotContext.Provider value={{ plot, updatePlot, design, updateDesign, resetContext, updatePlotWithReset }}>
      {children}
    </ScatterPlotContext.Provider>
  );
};
