import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useBox } from "../../../hooks/plots/useBox";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import {
  dependencyEqualsValue,
  dependencyNotEqualsValue,
  fetchHeaders,
  onDataRangeChangeSelection,
} from "../MVCShared/PlotGeneratorUtils";
import { Chart, LegendPosition, Orientation } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  AxisDropdownAttr,
  LegendAttr,
  CollapsibleAttr,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode } from "../MVCShared/CodeBuilder";

const BoxContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign, updatePlotWithReset } = useBox();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(boxPlot, [...boxPlot.baseAttrs, ...boxPlot.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const orientationDropdown = DropdownAttr({
    value: plot.orientation,
    onChange: (_, data) => updatePlot({ orientation: data.optionValue }),
    label: "Orientation",
    options: ["Horizontal", "Vertical"],
    codeKey: "orient",
    codeValueMap: Orientation,
    codeRequiresInteraction: true,
    dataTestID: "orientation",
  });

  const ColorBy = AxisDropdownAttr({
    value: plot.colorBy,
    onChange: (_, data) => updatePlot({ colorBy: data.optionValue }, "--None--"),
    label: "Color By:",
    options: ["--None--", ...plot.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: plot.hasHeaders,
  });

  const SaturationAttr = SpinnerAttr({
    label: "Saturation:",
    value: design.saturation,
    step: 0.01,
    max: 1,
    onChange: (_, data) => updateDesign({ saturation: data }),
    codeKey: "saturation",
    codeRequiresInteraction: true,
    dataTestID: "saturation",
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position:",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const boxPlot: Chart = {
    plotType: PlotType.box,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      orientationDropdown,
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) =>
          onDataRangeChangeSelection(newSelection, updatePlotWithReset),
      }),
      CheckBoxAttr({
        label: "Data Has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      AxisDropdownAttr({
        value: plot.xAxis,
        onChange: (_, data) => {
          updatePlot({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        dataTestID: "x-axis",
        label: "X-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "x",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.yAxis,
        onChange: (_, data) => {
          updatePlot({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        dataTestID: "y-axis",
        label: "Y-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "y",
        hasHeaders: plot.hasHeaders,
      }),
      ColorBy,
      DividerAttr(),
      HeadingAttr({
        title: "Whiskers",
        tooltip: "Compute length of the plot whiskers as proportion of the interquartile range",
      }),
      SpinnerAttr({
        step: 0.1,
        label: "Whiskers Range:",
        value: plot.whiskersRange,
        onChange: (_, data) => updatePlot({ whiskersRange: data }),
        codeKey: "whis",
        codeRequiresInteraction: true,
        dataTestID: "whiskers",
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Output",
        tooltip: "Determine where visualization will render",
      }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
    ],
    designAttrs: [
      HeadingAttr({
        title: "Chart Info",
        tooltip: null,
      }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "X-Axis Label:",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "Y-Axis Label:",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks:",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks:",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Color",
        tooltip: null,
      }),
      ColorPickerAttr({
        value: design.fill,
        label: "Fill:",
        onChange: (hexColor) => updateDesign({ fill: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      SaturationAttr,
      DividerAttr(),
      HeadingAttr({
        title: "Box Formatting",
        tooltip: null,
      }),
      SpinnerAttr({
        label: "Width:",
        value: design.width,
        step: 0.1,
        onChange: (_, data) => updateDesign({ width: data }),
        codeKey: "width",
        codeRequiresInteraction: true,
        dataTestID: "width",
      }),
      SpinnerAttr({
        label: "Line Width:",
        value: design.lineWidth,
        step: 1,
        suffix: " px",
        onChange: (_, data) => updateDesign({ lineWidth: parseInt(data) }),
        codeKey: "linewidth",
        codeRequiresInteraction: true,
        dataTestID: "line-width",
      }),
      CheckBoxAttr({
        value: design.dodge,
        onChange: (_, e) => updateDesign({ dodge: e.checked }),
        label: "Dodge:",
        codeKey: "dodge",
        codeRequiresInteraction: true,
        dataTestID: "dodge",
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Outlier Formatting",
        tooltip: null,
      }),
      SpinnerAttr({
        label: "Marker Size:",
        value: design.markerSize,
        step: 1,
        suffix: " px",
        onChange: (_, data) => updateDesign({ markerSize: parseInt(data) }),
        codeKey: "fliersize",
        codeRequiresInteraction: true,
        dataTestID: "marker-size",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top:",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right:",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
        ],
      }),
    ],
  };
  return boxPlot;
};

const BoxForm = () => {
  const boxPlot = BoxContext();
  return buildReactFromAttrs(boxPlot.baseAttrs);
};

export const BoxDesign = () => {
  const boxDesign = BoxContext();
  return buildReactFromAttrs(boxDesign.designAttrs);
};

export default BoxForm;
