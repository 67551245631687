import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface DisplotContextType extends BasePlotContext<Displot, DisplotDesign> {
  // TODO: specifics
}

interface Displot extends BasePlot {
  // TODO: specifics
  kind: ValueTracker<string>;
  row: string;
  col: string;
  isCollapsed: boolean;
}

interface DisplotDesign extends BaseDesign {
  // TODO: specifics
  height: ValueTracker<number>;
  aspect: ValueTracker<number>;
  legend: ValueTracker<boolean>;
  colWrap: ValueTracker<number>;
  isCollapsed: boolean;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
}

const initialState: Displot = {
  plotType: PlotType.distribution,
  dataRange: undefined,
  headers: [],
  xAxis: "",
  yAxis: "",
  kind: new ValueTracker("Hist"),
  colorBy: "",
  row: "",
  col: "",
  // Items for facetGrid start
  shareX: new ValueTracker(true),
  shareY: new ValueTracker(true),
  dropna: new ValueTracker(true),
  // end
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  isCollapsed: true,
  hasHeaders: true,
};

const designInitialState: DisplotDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  height: new ValueTracker(5),
  aspect: new ValueTracker(1),
  fill: new ValueTracker("#1f77b4"),
  palette: new ValueTracker("Tab10"),
  legend: new ValueTracker(true),
  colWrap: new ValueTracker(0),
  // Items for facetGrid start
  xlim: new ValueTracker(0),
  ylim: new ValueTracker(0),
  facetPalette: new ValueTracker("Tab10"),
  // end
  isCollapsed: true,
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
};

export const DisplotContext = createContext<DisplotContextType>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const useDisplot = () => {
  const context = useContext<DisplotContextType>(DisplotContext);
  return context;
};

export const DisplotProvider = ({ children }: { children: React.ReactNode }) => {
  const [plot, setPlot] = useState<Displot>(initialState);
  const [design, setDesign] = useState<DisplotDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <DisplotContext.Provider value={{ plot, updatePlot, design, updateDesign, resetContext, updatePlotWithReset }}>
      {children}
    </DisplotContext.Provider>
  );
};
