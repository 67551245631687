import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useViolin } from "../../../hooks/plots/useViolin";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import {
  dependencyEqualsValue,
  dependencyNotEqualsValue,
  fetchHeaders,
  onDataRangeChangeSelection,
} from "../MVCShared/PlotGeneratorUtils";
import { Chart, LegendPosition, Orientation } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  CollapsibleAttr,
  AxisDropdownAttr,
  LegendAttr,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode, CodeBuilder } from "../MVCShared/CodeBuilder";

const ViolinContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign, updatePlotWithReset } = useViolin();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(ViolinChart, [...ViolinChart.baseAttrs, ...ViolinChart.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const orientationDropdown = DropdownAttr({
    value: plot.orientation,
    onChange: (_, data) => updatePlot({ orientation: data.optionValue }),
    label: "Orientation",
    options: ["Horizontal", "Vertical"],
    codeKey: "orient",
    codeValueMap: Orientation,
    codeRequiresInteraction: true,
  });

  const xAxis = AxisDropdownAttr({
    value: plot.xAxis,
    onChange: (_, data) => {
      updatePlot({ xAxis: data.optionValue }, "--Select--");
      updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
    },
    label: "X-Axis:",
    options: ["--Select--", ...plot.headers],
    codeKey: "x",
    hasHeaders: plot.hasHeaders,
  });

  const BandwidthAttr = DropdownAttr({
    value: plot.bandwidth,
    onChange: (_, data) => updatePlot({ bandwidth: data.optionValue }),
    label: "Bandwidth",
    options: ["Scott", "Silverman", "Custom", "None"],
    codeKey: "bw_method",
    codeRequiresInteraction: true,
    dataTestID: "bandwidth",
  });

  BandwidthAttr.getCode = (code: CodeBuilder) => {
    if (plot.bandwidth.isUpdated === true) {
      plot.bandwidth.value === "Custom"
        ? null
        : code.plotAttrs.push(`bw_method="${plot.bandwidth.value.toLowerCase()}"`);
    }
  };

  // Specific rendering for custom
  const ColorBy = AxisDropdownAttr({
    value: plot.colorBy,
    onChange: (_, data) => updatePlot({ colorBy: data.optionValue }, "--None--"),
    label: "Color By:",
    options: ["--None--", ...plot.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: plot.hasHeaders,
  });

  const SaturationAttr = SpinnerAttr({
    label: "Saturation:",
    value: design.saturation,
    step: 0.01,
    max: 1,
    onChange: (_, data) => updateDesign({ saturation: data }),
    codeKey: "saturation",
    codeRequiresInteraction: true,
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position:",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const ViolinChart: Chart = {
    plotType: PlotType.violin,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      orientationDropdown,
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) =>
          onDataRangeChangeSelection(newSelection, updatePlotWithReset),
      }),
      CheckBoxAttr({
        label: "Data Has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      xAxis,
      AxisDropdownAttr({
        value: plot.yAxis,
        onChange: (_, data) => {
          updatePlot({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "y",
        hasHeaders: plot.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Grouping", tooltip: "Determine violin color" }),
      ColorBy,
      DividerAttr(),
      HeadingAttr({
        title: "Output",
        tooltip: "Determine where visualization will render",
      }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: plot.isCollapsed,
        toggle: () => updatePlot({ isCollapsed: !plot.isCollapsed }),
        children: [
          HeadingAttr({ title: "Kernel", tooltip: "Compute kernel density estimate" }),
          BandwidthAttr,
          SpinnerAttr({
            label: "",
            value: plot.custom,
            step: 0.1,
            max: 1,
            onChange: (_, data) => updatePlot({ custom: data }),
            visibleDependencies: [dependencyEqualsValue(BandwidthAttr, "Custom")],
            codeKey: "bw_adjust",
            dataTestID: "bw-custom",
          }),
          SpinnerAttr({
            label: "Cut:",
            value: plot.cut,
            step: 1,
            onChange: (_, data) => updatePlot({ cut: data }),
            codeKey: "cut",
            codeRequiresInteraction: true,
            dataTestID: "cut",
          }),
          SpinnerAttr({
            label: "Grid Size:",
            value: plot.gridSize,
            step: 1,
            onChange: (_, data) => updatePlot({ gridSize: data }),
            codeKey: "gridsize",
            codeRequiresInteraction: true,
            dataTestID: "grid",
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Scale", tooltip: "Determine violin widths" }),
      DropdownAttr({
        label: "Scale:",
        options: ["Area", "Count", "Width", "None"],
        value: plot.scale,
        codeKey: "density_norm",
        codeValueMap: { Area: "area", Count: "count", Width: "width" },
        codeRequiresInteraction: true,
        onChange: (_, data) => updatePlot({ scale: data.optionValue }),
      }),
      CheckBoxAttr({
        label: "Scale Color:",
        value: plot.scaleColor,
        onChange: (_, data) => updatePlot({ scaleColor: data.checked }),
        codeKey: "scale_hue",
        codeRequiresInteraction: true,
        dataTestID: "scale-color",
      }),
    ],
    designAttrs: [
      HeadingAttr({
        title: "Chart Info",
        tooltip: null,
      }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "X-Axis Label:",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "Y-Axis Label:",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks:",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks:",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      ColorPickerAttr({
        value: design.fill,
        label: "Fill:",
        onChange: (hexColor) => updateDesign({ fill: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      SaturationAttr,
      DividerAttr(),
      HeadingAttr({ title: "Violin Formatting" }),
      SpinnerAttr({
        label: "Width:",
        value: design.width,
        step: 0.1,
        onChange: (_, data) => updateDesign({ width: data }),
        codeKey: "width",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Line Width:",
        value: design.lineWidth,
        step: 0.1,
        suffix: " px",
        onChange: (_, data) => updateDesign({ lineWidth: parseInt(data) }),
        codeKey: "linewidth",
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.split,
        onChange: (_, e) => updateDesign({ split: e.checked }),
        label: "Split:",
        codeKey: "split",
        codeRequiresInteraction: true,
        dataTestID: "split",
      }),
      CheckBoxAttr({
        value: design.dodge,
        onChange: (_, e) => updateDesign({ dodge: e.checked }),
        label: "Dodge:",
        codeKey: "dodge",
        codeRequiresInteraction: true,
        enabledDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        dataTestID: "dodge",
      }),
      DropdownAttr({
        value: design.violinInner,
        onChange: (_, data) => updateDesign({ violinInner: data.optionValue }),
        label: "Violin Inner:",
        options: ["Box", "Quartile", "Point", "Stick", "None"],
        codeKey: "inner",
        codeValueMap: { Box: "box", Quartile: "quartile", Point: "point", Stick: "stick" },
        codeRequiresInteraction: true,
        dataTestID: "violin-inner",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top:",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right:",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
        ],
      }),
    ],
  };
  return ViolinChart;
};

const ViolinForm = () => {
  const ViolinChart = ViolinContext();
  return buildReactFromAttrs(ViolinChart.baseAttrs);
};

export const ViolinDesign = () => {
  const violinDesign = ViolinContext();
  return buildReactFromAttrs(violinDesign.designAttrs);
};

export default ViolinForm;
