import React from "react";
import { Dialog, DialogSurface, DialogTitle, DialogContent, DialogBody } from "@fluentui/react-components";
import Env from "xlcommon/src/environ";

export const UpdateRequired = () => {
  const version = Env.MANIFEST_VERSION_IN_STORE;

  return (
    <Dialog modalType="alert" open={true}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Add-in Update Required</DialogTitle>
          <DialogContent>Anaconda Toolbox has a new version available ({version}) and must be updated.</DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
