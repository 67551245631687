import React, { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft20Filled } from "@fluentui/react-icons";
import { Breadcrumb } from "../components/Typography";
import styles from "../styles.module.scss";
import "./Logo.scss";

export const Logo: React.FC<{ children?: ReactNode; title?: string; size?: number }> = ({
  children,
  title = "Anaconda Toolbox",
  size = 14,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split("/").filter(Boolean);

  if (paths.length >= 2 && paths[0] != "viz") {
    return <Breadcrumb text={`Back to ${title}`} onClick={() => navigate(-1)} />;
  }

  return (
    <div className="logo" style={{ fontSize: size }} onClick={() => navigate(`/back/?current=${location.pathname}`)}>
      {title === "Anaconda Toolbox" ? (
        <img src="/static/assets/toolbox-logo.svg" height={20} width={20} alt="anaconda icon logo" />
      ) : (
        <ChevronLeft20Filled color={styles.primary} />
      )}
      {/* TODO: Replace with brand variant color */}
      <h4 style={{ color: "#2C303A" }}>{title ?? "Anaconda"}</h4>
      {children}
    </div>
  );
};
