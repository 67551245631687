import React from "react";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";
import { Text } from "@fluentui/react-components";
import { InfoToolTip } from "./VizTooltip";
import "./Viz.scss";

export const PlotCard = ({
  type,
  imageSrc,
  tooltipInfo,
  img,
  onCardClick,
  description,
  dataTestID,
}: {
  type: string;
  imageSrc: string;
  tooltipInfo: string;
  img: string;
  description: string;
  onCardClick?: () => void;
  dataTestID?: string;
}) => {
  const handleToolTipClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div style={{ margin: "9px 0" }} onClick={onCardClick}>
      <Card
        data-testid={dataTestID}
        className="plot-card"
        focusMode="tab-only"
        orientation="horizontal"
        style={{ boxShadow: "0 0 1px", borderRadius: "9px", height: 40, cursor: "pointer" }}
      >
        <CardPreview style={{ marginLeft: "3px" }}>
          <img
            style={{ objectFit: "contain", height: 20, width: 20, position: "relative" }}
            src={imageSrc}
            alt="Plot image"
          />
        </CardPreview>
        <CardHeader
          style={{ flexShrink: "unset", display: "flex", alignItems: "center", flex: 1 }}
          header={<Text weight="semibold">{type}</Text>}
        />
        <div onClick={handleToolTipClick} style={{ display: "flex", justifyContent: "flex-end", flexGrow: "unset" }}>
          <InfoToolTip info={tooltipInfo} img={img} title={type} description={description} />
        </div>
      </Card>
    </div>
  );
};
