import React, { createContext, useContext, useState, useEffect } from "react";
import { CodeBuilder, buildPythonInExcelCode } from "../pages/Visualization/MVCShared/CodeBuilder";

interface GeneratedCodeContextType {
  generatedCode: CodeBuilder;
  setGeneratedCode: (generatedCode: CodeBuilder) => void;
  clearGeneratedCode: () => void;
  codeResult: string;
  setCodeResult: (typedCode: string) => void;
  typedCode: string;
  setTypedCode: (typedCode: string) => void;
}

const codeInitialState = null;
const codeResultInitialState = "";
const typedCodeInitialState = "";

export const GeneratedCodeContext = createContext<GeneratedCodeContextType>({
  generatedCode: null,
  setGeneratedCode: () => Promise<void>,
  clearGeneratedCode: () => Promise<void>,
  codeResult: codeResultInitialState,
  setCodeResult: () => Promise<void>,
  typedCode: typedCodeInitialState,
  setTypedCode: () => Promise<void>,
});

export const useGeneratedCodeContext = () => {
  return useContext(GeneratedCodeContext);
};

export const GeneratedCodeProvider = ({ children }: { children: React.ReactNode }) => {
  const [generatedCode, setGeneratedCode] = useState<CodeBuilder>();
  const [typedCode, setTypedCode] = useState<string>(typedCodeInitialState);
  const [codeResult, setCodeResult] = useState<string>(codeResultInitialState);

  const clearGeneratedCode = () => {
    setGeneratedCode(codeInitialState);
    setTypedCode(typedCodeInitialState);
  };

  useEffect(() => {
    const convertGeneratedCode = async () => {
      if (generatedCode?.source) {
        setCodeResult(await buildPythonInExcelCode(generatedCode, false));
        setTypedCode(await buildPythonInExcelCode(generatedCode, true));
      }
    };
    convertGeneratedCode();
  }, [generatedCode]);

  return (
    <GeneratedCodeContext.Provider
      value={{
        generatedCode,
        setGeneratedCode,
        clearGeneratedCode,
        typedCode,
        setTypedCode,
        codeResult,
        setCodeResult,
      }}
    >
      {children}
    </GeneratedCodeContext.Provider>
  );
};
