import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableFreezeRow20Regular,
  CloudArrowUp20Regular,
  CloudArrowDown20Regular,
  CloudArrowDown20Filled,
  CloudCheckmark20Regular,
  CloudDismiss20Regular,
  CloudError20Regular,
  DeleteRegular,
} from "@fluentui/react-icons";
import { Button, Text, Tooltip } from "@fluentui/react-components";
import { PopoverMenuButton } from "../../../components/PopoverMenuButton";
import { Row } from "../../../components/Layout/Space";
import { DataImportProps, ImportResolution } from "./ConnectToWorkbook";
import { DataExportProps, ExportResolution } from "./ConnectToCloud";
import { ConflictDialog, ConflictChoice } from "./ConflictDialog";
import ProjectsService from "../../../../data/projects/projects-api";
import { CloudProjectDefinitions, CloudProjectFileDefinition, Direction } from "../../../../data/models";
import { refreshRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { ProjectItemProps } from "../../Projects/ProjectFolder";
import styles from "../../../styles.module.scss";
import "./ConnectionItem.scss";

const VALID_FILE_SUFFIX = new Set(["csv"]);

export enum ConnectionKind {
  Unlinked = "unlinked",
  DownloadUpToDate = "downloadUpToDate",
  DownloadNewVersion = "downloadNewVersion",
  Upload = "upload",
  UploadConflict = "uploadConflict",
  UnknownDownload = "unknownDownload",
  UnknownUpload = "unknownUpload",
}

function Connector({ title, connection }: { title: string; connection: CloudProjectFileDefinition }) {
  return (
    <div>
      <Row gap={4} alignItems="center">
        <TableFreezeRow20Regular />
        <div
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "calc(100vw - 155px)",
          }}
        >
          {/* Fallback to title or filename if no source selection activity */}
          {!connection?.sourceSelection?.displayName && <Text>{title}</Text>}
          {connection?.sourceSelection && (
            <Row gap={2}>
              <Text style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {connection?.sourceSelection?.displayName}
              </Text>
              <Text data-testid="connection">{connection?.direction === Direction.Upload ? `->` : `<-`}</Text>
              <Text style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{connection?.fileName}</Text>
            </Row>
          )}
        </div>
      </Row>
    </div>
  );
}

export interface ConnectorItemProps {
  definitions: CloudProjectDefinitions["projects"];
  onlyConnections?: boolean;
}

function ConnectorItem({
  project,
  item,
  refreshItems,
  definitions,
  onlyConnections = false,
}: ConnectorItemProps & ProjectItemProps) {
  const navigate = useNavigate();

  const [conflictOpen, setConflictOpen] = useState<boolean>(false);

  const connectionKind = useRef<ConnectionKind>(ConnectionKind.Unlinked);
  const [connection, setConnection] = useState<CloudProjectFileDefinition>();
  useEffect(() => {
    (async () => {
      try {
        let conn = await getConnection(project.id, item.name);
        if (conn) {
          connectionKind.current = getConnectionKind(conn);
          setConnection({ ...conn });
        }
      } catch (e) {
        console.log("There was an error fetching file metadata: ", e);
      }
    })();
  }, []);

  async function getConnection(id: string, fileName: string): Promise<CloudProjectFileDefinition | null> {
    if (!definitions) return null;
    if (definitions[id] && definitions[id].files?.[fileName]) {
      const def: CloudProjectFileDefinition = definitions[id].files[fileName];
      if (def?.sourceSelection) {
        def.sourceSelection = await refreshRangeSelection(def.sourceSelection);
      }
      return def;
    }
    return null;
  }

  function getConnectionKind(conn: CloudProjectFileDefinition): ConnectionKind {
    if (conn) {
      if (conn.direction === Direction.Download) {
        if (!item) return ConnectionKind.UnknownDownload;
        if (conn.lastDownloaded < item.last_modified) {
          return ConnectionKind.DownloadNewVersion;
        }
        // We have the latest version
        return ConnectionKind.DownloadUpToDate;
      } else if (conn.direction === Direction.Upload) {
        if (conn.lastUploaded < item.last_modified) {
          // Someone else modified the file
          return ConnectionKind.UploadConflict;
        }
        // TODO: figure out how to tell if local table is modified
        return ConnectionKind.Upload;
      }
    }
    // Default download icon when no connection exists
    return ConnectionKind.Unlinked;
  }

  function getStatusIcon() {
    switch (connectionKind.current) {
      case ConnectionKind.DownloadNewVersion:
        return <CloudArrowDown20Filled color={styles.baseText} />;
      case ConnectionKind.DownloadUpToDate:
        return <CloudCheckmark20Regular color={styles.primary} />;
      case ConnectionKind.Upload:
        return <CloudArrowUp20Regular color={styles.baseText} />;
      case ConnectionKind.UploadConflict:
        return <CloudDismiss20Regular color={styles.notificationErrorBg} />;
      case ConnectionKind.UnknownDownload:
      case ConnectionKind.UnknownUpload:
        return <CloudError20Regular color={styles.notificationErrorBg} />;
      case ConnectionKind.Unlinked:
        return <CloudArrowDown20Regular color={styles.gray400} />;
    }
  }

  function getTooltip(): string {
    switch (connectionKind.current) {
      case ConnectionKind.DownloadNewVersion: {
        const [d, t] = item.last_modified.slice(0, 19).split("T");
        return `Newer version of cloud data available: ${d} ${t}`;
      }
      case ConnectionKind.DownloadUpToDate:
        return "You have the latest version";
      case ConnectionKind.Upload:
        return "Re-upload data to Anaconda Cloud";
      case ConnectionKind.UploadConflict:
        return "Data in the Cloud has been modified since your last upload";
      case ConnectionKind.UnknownDownload:
        return "Data in the Cloud has been deleted or moved since your last download";
      case ConnectionKind.UnknownUpload:
        return "Data uploaded to the Cloud has been deleted or moved";
      case ConnectionKind.Unlinked:
        return "";
    }
  }

  async function onIconClick() {
    switch (connectionKind.current) {
      case ConnectionKind.DownloadNewVersion:
        navigate("/data/import", {
          state: {
            selectedImportFileName: connection.fileName,
            projectId: project.id,
            query: connection.query,
            destSelection: await refreshRangeSelection(connection.sourceSelection),
            resolution: ImportResolution.NewVersion,
          } as DataImportProps,
        });
        return;
      case ConnectionKind.DownloadUpToDate:
        navigate("/data/import", {
          state: {
            selectedImportFileName: connection.fileName,
            projectId: project.id,
            query: connection.query,
            destSelection: await refreshRangeSelection(connection.sourceSelection),
            resolution: ImportResolution.ReDownload,
          } as DataImportProps,
        });
        return;
      case ConnectionKind.Upload:
        navigate("/data/export", {
          state: {
            selectedRange: connection.sourceSelection,
            project: { id: project.id, name: project.name },
            fileName: connection.fileName,
            resolution: ExportResolution.NewVersion,
          } as DataExportProps,
        });
        return;
      case ConnectionKind.UploadConflict:
        setConflictOpen(true);
        return;
      case ConnectionKind.UnknownDownload:
        // TODO: show dialog to remove connection or open versions view (when available)
        return;
      case ConnectionKind.UnknownUpload:
        // TODO: show conflict dialog
        return;
      case ConnectionKind.Unlinked:
        navigate("/data/import", {
          state: {
            selectedImportFileName: item.name,
            projectId: project.id,
          } as DataImportProps,
        });
        return;
    }
  }

  async function deleteItem(projId: string, fileName: string) {
    try {
      await ProjectsService.deleteFileByName(projId, fileName);
      await refreshItems();
    } catch (e) {
      console.log(e);
    }
  }

  async function conflictCallback(choice: ConflictChoice) {
    switch (choice) {
      case ConflictChoice.OverwriteCloud:
        navigate("/data/export", {
          state: {
            selectedRange: connection.sourceSelection,
            project: { id: project.id, name: project.name },
            fileName: connection.fileName,
            resolution: ExportResolution.OverwriteCloudVersion,
          } as DataExportProps,
        });
        break;
      case ConflictChoice.UploadNewFilename:
        navigate("/data/export", {
          state: {
            selectedRange: connection.sourceSelection,
            project: { id: project.id, name: project.name },
            fileName: connection.fileName,
            resolution: ExportResolution.NewFilename,
          } as DataExportProps,
        });
        break;
      case ConflictChoice.OverwriteWorkbook:
        navigate("/data/import", {
          state: {
            selectedImportFileName: connection.fileName,
            projectId: project.id,
            destSelection: await refreshRangeSelection(connection.sourceSelection),
            resolution: ImportResolution.OverwriteWorkbook,
          } as DataImportProps,
        });
        break;
    }
  }

  function needsConflictDialog() {
    return (
      connectionKind.current === ConnectionKind.UploadConflict ||
      connectionKind.current === ConnectionKind.UnknownUpload
    );
  }

  // Parameter set to only render data connections (versus unlinked workbook data or files)
  if (onlyConnections && !connection?.sourceSelection?.displayName) {
    return null;
  }

  // Only show files with known suffix
  const fileSuffix = item.name.split(".").slice(-1)[0].toLowerCase();
  if (!VALID_FILE_SUFFIX.has(fileSuffix)) return null;

  return (
    <div className="connection-item">
      {needsConflictDialog() && (
        <ConflictDialog
          open={conflictOpen}
          setOpen={setConflictOpen}
          kind={connectionKind.current}
          item={item}
          connection={connection}
          callback={conflictCallback}
        />
      )}
      <Row gap={4} alignItems="center" justifyContent="space-between">
        <Connector title={item.name} connection={connection} />
        <Row gap={8} alignItems="center">
          {connectionKind.current !== ConnectionKind.Unlinked && (
            <Tooltip withArrow hideDelay={0} content={getTooltip()} relationship="description">
              <Button
                style={{ marginLeft: 10, position: "absolute", right: 20 }}
                size="small"
                onClick={onIconClick}
                appearance="subtle"
                icon={getStatusIcon()}
                iconPosition="before"
                data-testid="import-arrow"
              />
            </Tooltip>
          )}
          {connectionKind.current === ConnectionKind.Unlinked && (
            <Button
              style={{ marginLeft: 10, position: "absolute", right: 20 }}
              size="small"
              onClick={onIconClick}
              appearance="subtle"
              icon={getStatusIcon()}
              iconPosition="before"
              data-testid="import-arrow"
            />
          )}
          {/* Connection Actions Menu */}
          {/* TODO: Add an Unlink option for connections */}
          {!onlyConnections && (
            <PopoverMenuButton
              kind="file"
              id={project.id}
              buttonStyle={{ position: "absolute", right: -15 }}
              actions={[
                {
                  actionName: "Delete",
                  onClickAction: async () => await deleteItem(project.id, item.name),
                  icon: <DeleteRegular />,
                },
              ]}
            />
          )}
        </Row>
      </Row>
    </div>
  );
}

export function ConnectorItemCurry(props: ConnectorItemProps) {
  return (baseProps: ProjectItemProps) => {
    return ConnectorItem({ ...baseProps, ...props });
  };
}
