import React, { useState } from "react";
import { Tab, TabList } from "@fluentui/react-components";
import Switch from "react-switch";
import { useParams, useNavigate } from "react-router-dom";
import "./Viz.scss";
import Preview from "./Preview";
import UserEditor from "./UserEditor";
import ContinuePopUp from "./VizSharedComponents/PopUp";

function CodePreviewer() {
  const navigate = useNavigate();
  const { plotType, tab = "code" } = useParams();
  const [editorPopup, setEditorPopUp] = useState<boolean>(false);

  return (
    <>
      <TabList
        selectedValue={tab}
        onTabSelect={(_, selectedTab) => navigate(`/viz/${plotType}/previewer/${selectedTab.value}`)}
      >
        <Tab data-testid="code-tab" value="code">
          Code
        </Tab>
        <Tab data-testid="preview-tab" value="preview">
          Preview
        </Tab>
        <div className="editor-switch">
          <div style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
            <Switch
              checked={true}
              onChange={() => {
                setEditorPopUp(true);
              }}
              onColor="#0CCA4A"
              handleDiameter={15}
              onHandleColor="#FFFFFF"
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={36}
              activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.2)"
              data-testid="code-switch"
            />
            <ContinuePopUp
              dialogTitle="Overwrite Warning"
              dialogContent="Switching out of code mode will discard any changes you made to the code."
              isPopUpVisible={editorPopup}
              hidePopUp={() => {
                setEditorPopUp(false);
              }}
              onHandleContinue={() => {
                navigate(`/viz/${plotType}/setup`);
              }}
            />
            <span
              style={{
                marginLeft: "5px",
                fontSize: "13px",
                color: "#000000",
              }}
            >
              Code Mode
            </span>
          </div>
        </div>
      </TabList>
      <div className="tab-content">
        {tab === "code" && <UserEditor />}
        {tab === "preview" && <Preview />}
      </div>
    </>
  );
}
export default CodePreviewer;
