import React, { useState } from "react";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import ContinuePopUp from "./VizSharedComponents/PopUp";
import { snakeEyesRecord } from "../../../analytics/snake-eyes";
import styles from "../../styles.module.scss";
import "./Viz.scss";

interface ActionButtonsProps {
  onRestart?: () => Promise<void>;
  onCreateClick: () => Promise<void>;
  disabled?: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onCreateClick, onRestart, disabled }) => {
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleContinueClick = async () => {
    setIsPopupVisible(false);
    if (onRestart) {
      await onRestart();
      navigate("/viz/restart");
    }
  };

  return (
    <div className="viz-action">
      <Button
        appearance="primary"
        disabled={disabled}
        iconPosition="after"
        onClick={() => {
          onCreateClick();
          snakeEyesRecord({
            event: "viz/chart_created",
          });
        }}
        style={{ padding: 8 }}
      >
        Create
      </Button>
      <Button
        data-testid="restart"
        appearance="subtle"
        onClick={() => setIsPopupVisible(true)}
        style={{ color: styles.gray500, padding: 8 }}
      >
        Restart
      </Button>
      <ContinuePopUp
        dialogTitle="Restart"
        dialogContent="Are you sure you want to discard your visualization?"
        isPopUpVisible={isPopupVisible}
        hidePopUp={() => setIsPopupVisible(false)}
        onHandleContinue={handleContinueClick}
      />
    </div>
  );
};

export default ActionButtons;
