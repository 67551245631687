import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { usePair } from "../../../hooks/plots/usePair";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import { Chart, LegendPosition, Markers } from "../MVCShared/types";
import {
  AxisDropdownAttr,
  CheckBoxAttr,
  CollapsibleAttr,
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  LegendAttr,
  MultiSelectDropdownAttr,
  OutputAttr,
  PaletteAttr,
  SpinnerAttr,
} from "../MVCShared/PlotAttributes";
import { CodeBuilder, buildCode, buildReactFromAttrs } from "../MVCShared/CodeBuilder";
import { dependencyNotEqualsValue, fetchHeaders, onDataRangeChangeSelection } from "../MVCShared/PlotGeneratorUtils";
import { DiagonolKws, PlotStyle } from "./PairPlotKws";

const PairPlotContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign, updatePlotWithReset } = usePair();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(PairPlotChart, [...PairPlotChart.baseAttrs, ...PairPlotChart.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const MarkerAttr = DropdownAttr({
    label: "Marker:",
    value: design.markers,
    options: ["Point", "Circle", "Plus", "Star", "Diamond", "X"],
    onChange: (_, e) => updateDesign({ markers: e.selectedOptions }),
    codeKey: "marker",
    codeRequiresInteraction: true,
    multiselect: true,
    selectedOptons: design.markers,
    dataTestID: "marker",
  });

  MarkerAttr.getCode = (code: CodeBuilder) => {
    if (design.markers.length > 0) {
      const markerCodes = design.markers.map((marker) => `'${Markers[marker]}' `);
      code.plotAttrs.push(`markers=[${markerCodes}]`);
    }
  };

  const ColorBy = AxisDropdownAttr({
    value: plot.colorBy,
    onChange: (_, data) => updatePlot({ colorBy: data.optionValue }, "--None--"),
    label: "Color By:",
    options: ["--None--", ...plot.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: plot.hasHeaders,
  });

  const paletteAttr = PaletteAttr({
    value: design.palette,
    onChange: (_, data) => {
      updateDesign({ palette: data.optionText });
    },
    codeKey: "palette",
    placeholder: "Accent",
    codeRequiresInteraction: true,
    dataTestID: "palette",
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position:",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const PairPlotChart: Chart = {
    plotType: PlotType.pairwise,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) =>
          onDataRangeChangeSelection(newSelection, updatePlotWithReset),
      }),
      CheckBoxAttr({
        label: "Data Has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      // Note: Users can plot x & y variables or just pass in all the variables they want plotted, I'm not sure what we want but both are cool to me
      MultiSelectDropdownAttr({
        value: plot.vars,
        onChange: (_, data) => {
          updatePlot({ vars: data.selectedOptions });
        },
        dataTestID: "vars",
        label: "Variables",
        options: plot.headers,
        codeKey: "vars",
        multiselect: true,
        selectedOptons: plot.vars,
        hasHeaders: plot.hasHeaders,
      }),
      MultiSelectDropdownAttr({
        value: plot.xVars,
        onChange: (_, data) => {
          updatePlot({ xVars: data.selectedOptions });
        },
        dataTestID: "x-var",
        label: "Column",
        options: plot.headers,
        codeKey: "x_vars",
        multiselect: true,
        selectedOptons: plot.xVars,
        hasHeaders: plot.hasHeaders,
      }),
      MultiSelectDropdownAttr({
        value: plot.yVars,
        onChange: (_, data) => {
          updatePlot({ yVars: data.selectedOptions });
        },
        dataTestID: "y-var",
        label: "Row:",
        options: plot.headers,
        codeKey: "y_vars",
        multiselect: true,
        selectedOptons: plot.yVars,
        hasHeaders: plot.hasHeaders,
      }),
      ColorBy,
      CheckBoxAttr({
        label: "Drop NA Values:",
        value: plot.dropna,
        onChange: (_, e) => updatePlot({ dropna: e.checked }),
        codeKey: "dropna",
        codeRequiresInteraction: true,
        dataTestID: "dropna",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Chart Configuration", tooltip: "Configure subplots and layout" }),
      DropdownAttr({
        value: plot.kind,
        onChange: (_, data) => {
          updatePlot({ kind: data.optionValue });
        },
        dataTestID: "kind",
        label: "Subplot Type:",
        options: ["Scatter", "Hist", "Kde", "Reg"],
        codeValueMap: { Scatter: "scatter", Hist: "hist", Kde: "kde", Reg: "reg" },
        codeRequiresInteraction: true,
        codeKey: "kind",
      }),
      DropdownAttr({
        value: plot.diagKind,
        onChange: (_, data) => {
          updatePlot({ diagKind: data.optionValue });
        },
        dataTestID: "diagKind",
        label: "Diagonal Type:",
        options: ["Auto", "Hist", "Kde"],
        codeValueMap: { Auto: "auto", Hist: "hist", Kde: "kde" },
        codeKey: "diag_kind",
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        label: "Plot Lower Triangle Only:",
        value: plot.corner,
        onChange: (_, e) => updatePlot({ corner: e.checked }),
        codeKey: "corner",
        codeRequiresInteraction: true,
        dataTestID: "corner",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Output", tooltip: "Determine where visualization will render" }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
      DividerAttr(),
    ],
    designAttrs: [
      PlotStyle({
        lineStyle: design.lineStyle,
        lineWidth: design.lineWidth,
        markerSize: design.markerSize,
        codeRequiresInteraction: true,
      }),
      HeadingAttr({ title: "Chart Info" }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      paletteAttr,
      DividerAttr(),
      HeadingAttr({ title: "Markers" }),
      MarkerAttr,
      SpinnerAttr({
        label: "Marker Size:",
        value: design.markerSize,
        step: 1,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ markerSize: data }),
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Line Style" }),
      DropdownAttr({
        label: "Line Style:",
        value: design.lineStyle,
        onChange: (_, data) => updateDesign({ lineStyle: data.optionValue }),
        options: ["Solid", "Dashed"],
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Line Width:",
        value: design.lineWidth,
        onChange: (_, data) => updateDesign({ lineWidth: parseInt(data) }),
        suffix: " px",
        step: 1,
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Scale", tooltip: "Set chart size" }),
      SpinnerAttr({
        label: "Height:",
        value: design.height,
        step: 0.1,
        max: 2 ** 32 - 1,
        suffix: " in",
        onChange: (_, data) => updateDesign({ height: parseFloat(data) }),
        codeKey: "height",
        codeRequiresInteraction: true,
        dataTestID: "height",
      }),
      SpinnerAttr({
        label: "Aspect Ratio:",
        value: design.aspect,
        step: 0.1,
        max: 2 ** 32 - 1,
        suffix: " in",
        onChange: (_, data) => updateDesign({ aspect: parseFloat(data) }),
        codeKey: "aspect",
        codeRequiresInteraction: true,
        dataTestID: "aspect",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top:",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right:",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          DividerAttr(),
          DiagonolKws({
            stat: design.stat,
            element: design.element,
            bins: design.bin,
            codeRequiresInteraction: true,
          }),
          DropdownAttr({
            label: "Element",
            options: ["Bars", "Step", "Poly", "None"],
            placeholder: "Bar",
            value: design.element,
            onChange: (_, data) => updateDesign({ element: data.optionValue }),
            codeRequiresInteraction: true,
          }),
          DropdownAttr({
            value: design.stat,
            onChange: (_, data) => updateDesign({ stat: data.optionValue }, "--Select--"),
            label: "Bin Statistic",
            options: ["--Select--", "Count", "Frequency", "Probability", "Percent", "Density"],
          }),
          SpinnerAttr({
            onChange: (_, data) => updateDesign({ bin: data }),
            label: "Bin Size",
            step: 1,
            value: design.bin,
            dataTestID: "bin",
          }),
        ],
      }),

      DividerAttr(),
    ],
  };
  return PairPlotChart;
};

const PairForm = () => {
  const pairChart = PairPlotContext();
  return buildReactFromAttrs(pairChart.baseAttrs);
};

export const PairDesign = () => {
  const pairDesign = PairPlotContext();
  return buildReactFromAttrs(pairDesign.designAttrs);
};

export default PairForm;
