import React from "react";
import { useNavigate } from "react-router-dom";
import { PlotCard } from "./Visualization/PlotCard";
import "./Visualization/Viz.scss";
import { snakeEyesRecord } from "../../analytics/snake-eyes";

type PlotTypes = {
  type: string;
  plotType: string;
  imageSrc: string;
  tooltipInfo: string;
  img: string;
  description: string;
};

const plots: PlotTypes[] = [
  {
    type: "Bar Plot",
    plotType: "bar",
    imageSrc: "/static/assets/bar-clustered.svg",
    tooltipInfo: `Show point estimates and errors as rectangle bars.\nClick for more information.`,
    img: "/static/assets/bar-plot.png",
    description: "Show point estimates and errors as rectangle bars.",
  },
  {
    type: "Box Plot",
    plotType: "box",
    imageSrc: "/static/assets/box.svg",
    tooltipInfo: `Draw a box plot to show distributions with respect to categories.\nClick for more information.`,
    img: "/static/assets/box-plot.png",
    description: "Draw a box plot to show distributions with respect to categories.",
  },
  {
    type: "Count Plot",
    plotType: "count",
    imageSrc: "/static/assets/count.svg",
    tooltipInfo: `Show the counts of observations in each categorical bin using bars.\nClick for more information.`,
    img: "/static/assets/count-plot.png",
    description: "Show the counts of observations in each categorical bin using bars.",
  },
  {
    type: "Distribution Plot",
    plotType: "distribution",
    imageSrc: "/static/assets/displot-green.svg",
    tooltipInfo: `Visualize the univariate or bivariate distribution of data.\nClick for more information.`,
    img: "/static/assets/distribution.png",
    description: "Visualize the univariate or bivariate distribution of data.",
  },
  {
    type: "Line Plot",
    plotType: "line",
    imageSrc: "/static/assets/line.svg",
    tooltipInfo: `Draw a line plot with possibility of several semantic groupings.\nClick for more information.`,
    img: "/static/assets/lineplot.png",
    description: "Draw a line plot with possibility of several semantic groupings.",
  },
  {
    type: "Pairwise Plot",
    plotType: "pairwise",
    imageSrc: "/static/assets/pairplot-green.svg",
    tooltipInfo: `Plot pairwise relationships in a dataset.\nClick for more information.`,
    img: "/static/assets/pairwise.png",
    description: "Plot pairwise relationships in a dataset.",
  },
  {
    type: "Regression Plot",
    plotType: "regression",
    imageSrc: "/static/assets/regression.svg",
    tooltipInfo: `Plot data and a linear regression model fit.\nClick for more information.`,
    img: "/static/assets/regression-plot.png",
    description: "Plot data and a linear regression model fit.",
  },
  {
    type: "Scatter Plot",
    plotType: "scatter",
    imageSrc: "/static/assets/scatter.svg",
    tooltipInfo: `Draw a scatter plot with possibility of several semantic groupings.\nClick for more information.`,
    img: "/static/assets/scatter-plot.png",
    description: "Draw a scatter plot with possibility of several semantic groupings.",
  },
  {
    type: "Violin Plot",
    plotType: "violin",
    imageSrc: "/static/assets/violin.svg",
    tooltipInfo: `Plot data and a linear regression model fit.\nClick for more information.`,
    img: "/static/assets/violin-plot.png",
    description: "Draw a combination of boxplot and kernel density estimate",
  },
];

function Visualizations() {
  const navigate = useNavigate();

  function handlePlotClick(plotType: string) {
    navigate(`/viz/${plotType}`);
    snakeEyesRecord({
      event: "viz/chart_selected",
      eventParams: { chart: plotType },
    });
  }

  return (
    <>
      <div style={{ fontSize: 14, color: "#2C303A", left: 0, fontWeight: 600 }}>Create Visualization</div>
      <p>Choose one of the following plot types to visualize the data in your sheet</p>
      {plots.map((plot, index) => (
        <PlotCard
          key={index}
          type={plot.type}
          imageSrc={plot.imageSrc}
          tooltipInfo={plot.tooltipInfo}
          img={plot.img}
          onCardClick={() => handlePlotClick(plot.plotType)}
          description={plot.description}
          dataTestID={plot.plotType}
        />
      ))}
    </>
  );
}

export default Visualizations;
