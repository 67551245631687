import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useLine } from "../../../hooks/plots/useLine";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import { Axes, Chart, Estimators, IntervalOptions, Markers, LineStyle, LegendPosition } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  CollapsibleAttr,
  ColorPickerAttr,
  CheckBoxAttr,
  SpinnerAttr,
  PaletteAttr,
  AxisDropdownAttr,
  LegendAttr,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs, CodeBuilder } from "../MVCShared/CodeBuilder";
import {
  dependencyEqualsValue,
  dependencyInValues,
  dependencyNotEqualsValue,
  fetchHeaders,
  onDataRangeChangeSelection,
} from "../MVCShared/PlotGeneratorUtils";

const LineContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign, updatePlotWithReset } = useLine();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(linePlot, [...linePlot.baseAttrs, ...linePlot.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const LegendStyleAttr = DropdownAttr({
    label: "Legend Style:",
    value: design.legendStyle,
    options: ["--Select--", "Auto", "Brief", "Full", "False"],
    onChange: (_, e) => updateDesign({ legendStyle: e.optionValue }, "--Select--"),
    codeKey: "legend",
    codeRequiresInteraction: true,
  });

  LegendStyleAttr.getCode = (code: CodeBuilder) => {
    if (design.legendStyle.isUpdated) {
      design.legendStyle.value === "False"
        ? code.plotAttrs.push("legend=False")
        : code.plotAttrs.push(`legend="${design.legendStyle.value.toLowerCase()}"`);
    }
  };

  const MarkerAttr = DropdownAttr({
    label: "Marker Style:",
    value: design.marker,
    placeholder: "--None--",
    options: ["--None--", "Point", "Circle", "Plus", "Star", "Diamond", "X"],
    onChange: (_, e) => updateDesign({ marker: e.optionValue }, "--None--"),
    codeKey: "marker",
    codeValueMap: Markers,
    codeRequiresInteraction: true,
  });

  const errorBar = DropdownAttr({
    value: plot.errorBar,
    label: "Error Bar:",
    placeholder: "--None--",
    onChange: (_, data) => updatePlot({ errorBar: data.optionValue }, "--None--"),
    options: IntervalOptions,
  });

  const ColorBy = AxisDropdownAttr({
    value: plot.colorBy,
    onChange: (_, data) => updatePlot({ colorBy: data.optionValue }, "--None--"),
    label: "Color By:",
    options: ["--None--", ...plot.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: plot.hasHeaders,
  });
  // Two intervals are used because they have different max values and initial setpoints
  // based on the error bar chosen. Only one will be shown at a time because the
  // visibleDependencies are mutually exclusive.
  const primaryInterval = SpinnerAttr({
    label: "Interval:",
    value: plot.primaryInterval,
    step: 1,
    max: 100,
    suffix: "%",
    onChange: (_, data) => updatePlot({ primaryInterval: parseInt(data) }),
    visibleDependencies: [dependencyInValues(errorBar, ["CI", "PI", "None"])],
    enabledDependencies: [dependencyInValues(errorBar, ["CI", "PI"])],
  });
  primaryInterval.getCode = (code: CodeBuilder) => {
    code.plotAttrs.push(`errorbar=('${plot.errorBar.toLowerCase()}', ${plot.primaryInterval})`);
  };

  const secondaryInterval = SpinnerAttr({
    label: "Interval:",
    value: plot.secondInterval,
    step: 1,
    max: Infinity,
    suffix: "%",
    onChange: (_, data) => updatePlot({ secondInterval: parseInt(data) }),
    visibleDependencies: [dependencyInValues(errorBar, ["SE", "SD"])],
    enabledDependencies: [dependencyInValues(errorBar, ["SE", "SD"])],
  });
  secondaryInterval.getCode = (code: CodeBuilder) => {
    code.plotAttrs.push(`errorbar=('${plot.errorBar.toLowerCase()}', ${plot.secondInterval})`);
  };

  const error_kws = {};

  const errorBarColor = ColorPickerAttr({
    value: design.errorBarColor,
    label: "Error Bar Color:",
    onChange: (hexColor) => updateDesign({ errorBarColor: `#${hexColor}` }),
  });

  error_kws["ecolor"] = design.errorBarColor.value;

  const errorBarThickness = SpinnerAttr({
    label: "Error Bar Thickness:",
    value: design.errorBarWidth,
    onChange: (_, data) => updateDesign({ errorBarWidth: parseInt(data) }),
    suffix: " " + "px",
    step: 1,
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
    dataTestID: "errwidth",
  });

  error_kws["capthick"] = design.errorBarWidth.value;

  const errorBarCapWidth = SpinnerAttr({
    label: "Error Bar Cap Width:",
    value: design.errorBarCapWidth,
    step: 0.1,
    max: 1,
    onChange: (_, data) => updateDesign({ errorBarCapWidth: parseFloat(data) }),
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
    dataTestID: "capsize",
  });

  error_kws["capsize"] = design.errorBarCapWidth.value;

  const errorStyle = DropdownAttr({
    label: "Error Style:",
    value: design.errorStyle,
    onChange: (_, data) => updateDesign({ errorStyle: data.optionValue }, "--Select--"),
    options: ["--Select--", "Bars"],
    codeKey: "err_style",
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "None")],
    dataTestID: "error-style",
  });

  errorStyle.getCode = (code: CodeBuilder) => {
    if (design.errorStyle === "Bars") {
      code.plotAttrs.push(
        `err_style="bars", err_kws={${Object.entries(error_kws)
          .map(([key, value]) => `'${key}': ${JSON.stringify(value)}`)
          .join(", ")}}`
      );
    }
  };

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position:",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const linePlot: Chart = {
    plotType: PlotType.line,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) =>
          onDataRangeChangeSelection(newSelection, updatePlotWithReset),
      }),
      CheckBoxAttr({
        label: "Data Has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      CheckBoxAttr({
        label: "Sort",
        value: plot.sort,
        onChange: (_, data) => updatePlot({ sort: data.checked }),
        codeKey: "sort",
        codeRequiresInteraction: true,
        dataTestID: "sort",
      }),
      AxisDropdownAttr({
        value: plot.xAxis,
        onChange: (_, data) => {
          updatePlot({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        label: "X-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "x",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.yAxis,
        onChange: (_, data) => {
          updatePlot({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis:",
        options: ["--Select--", ...plot.headers],
        codeKey: "y",
        hasHeaders: plot.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Grouping",
        tooltip: "Select parameters to determine line and marker color, size, and style",
      }),
      ColorBy,
      AxisDropdownAttr({
        value: plot.styleMarkersBy,
        onChange: (_, data) => updatePlot({ styleMarkersBy: data.optionValue }, "--None--"),
        label: "Style Markers By:",
        options: ["--None--", ...plot.headers],
        placeholder: "--None--",
        codeKey: "markers",
        dataTestID: "styleByMarkers",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.styleLinesBy,
        onChange: (_, data) => updatePlot({ styleLinesBy: data.optionValue }, "--None--"),
        label: "Style Lines By:",
        options: ["--None--", ...plot.headers],
        codeKey: "style",
        dataTestID: "styleByLines",
        hasHeaders: plot.hasHeaders,
      }),
      AxisDropdownAttr({
        value: plot.sizeByLines,
        onChange: (_, data) => updatePlot({ sizeByLines: data.optionValue }, "--None--"),
        label: "Size Lines By:",
        options: ["--None--", ...plot.headers],
        placeholder: "--None--",
        codeKey: "size",
        dataTestID: "styleBySize",
        hasHeaders: plot.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Output", tooltip: "Determine where visualization will render" }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: plot.isCollapsed,
        toggle: () => updatePlot({ isCollapsed: !plot.isCollapsed }),
        children: [
          HeadingAttr({
            title: "Error",
            tooltip: "Measure error in data",
          }),
          DropdownAttr({
            value: plot.estimator,
            onChange: (_, data) => updatePlot({ estimator: data.optionValue }, "--None--"),
            label: "Estimator:",
            options: ["--None--", "Mean", "Median", "Max", "Min"],
            placeholder: "--None--",
            codeKey: "estimator",
            codeValueMap: Estimators,
          }),
          errorBar,
          primaryInterval,
          secondaryInterval,
          SpinnerAttr({
            label: "Bootstrap Samples:",
            value: plot.bootstrapSamples,
            step: 1,
            onChange: (_, data) => updatePlot({ bootstrapSamples: data }),
            codeKey: "n_boot",
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
          }),
          SpinnerAttr({
            label: "Seed:",
            value: plot.seed,
            step: 1,
            onChange: (_, data) => updatePlot({ seed: data }),
            codeKey: "seed",
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Axes",
        tooltip: "Set scale for axes",
      }),
      DropdownAttr({
        value: plot.xAxisScale,
        onChange: (_, data) => updatePlot({ xAxisScale: data.optionValue }),
        label: "X-Axis Scale:",
        options: ["Linear", "Log"],
        placeholder: "Linear",
        callKey: "xscale",
        codeValueMap: Axes,
      }),
      DropdownAttr({
        value: plot.yAxisScale,
        onChange: (_, data) => updatePlot({ yAxisScale: data.optionValue }),
        label: "Y-Axis Scale:",
        options: ["Linear", "Log"],
        placeholder: "Linear",
        callKey: "yscale",
        codeValueMap: Axes,
      }),
    ],
    designAttrs: [
      HeadingAttr({ title: "Chart Info" }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "X-Axis Label:",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "Y-Axis Label:",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks:",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks:",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      ColorPickerAttr({
        value: design.fill,
        label: "Fill:",
        onChange: (hexColor) => updateDesign({ fill: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Markers" }),
      MarkerAttr,
      DividerAttr(),
      HeadingAttr({ title: "Line Style" }),
      DropdownAttr({
        label: "Line Style:",
        value: design.lineStyle,
        onChange: (_, data) => updateDesign({ lineStyle: data.optionValue }),
        options: ["Solid", "Dashed"],
        codeKey: "linestyle",
        codeValueMap: LineStyle,
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Line Width:",
        value: design.lineWidth,
        onChange: (_, data) => updateDesign({ lineWidth: parseInt(data) }),
        suffix: " px",
        step: 1,
        codeKey: "linewidth",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      LegendStyleAttr,
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isDesignCollapsed,
        toggle: () => updateDesign({ isDesignCollapsed: !design.isDesignCollapsed }),
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top:",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right:",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          DividerAttr(),
          HeadingAttr({ title: "Error" }),
          errorBarColor,
          errorBarThickness,
          errorBarCapWidth,
          errorStyle,
        ],
      }),
    ],
  };
  return linePlot;
};

const LineForm = () => {
  const LinePlot = LineContext();
  return buildReactFromAttrs(LinePlot.baseAttrs);
};

export const LineDesign = () => {
  const LineDesign = LineContext();
  return buildReactFromAttrs(LineDesign.designAttrs);
};

export default LineForm;
