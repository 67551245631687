import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface BarPlotContext extends BasePlotContext<BarPlot, BarDesign> {
  // TODO: specifics
}

interface BarPlot extends BasePlot {
  // TODO: specifics
  primaryInterval?: number;
  secondInterval?: number;
  bootstrapSamples?: ValueTracker<number>;
  seed?: ValueTracker<number>;
  estimator?: string;
  errorBar?: string;
  isCollapsed: boolean;
  hasHeaders: boolean;
}

interface BarDesign extends BaseDesign {
  // TODO: specifics
  colorOrder?: string;
  width: ValueTracker<number>;
  errorBarWidth: ValueTracker<number>;
  errorBarCapWidth: ValueTracker<number>;
  errorColor: ValueTracker<string>;
  dodge: ValueTracker<boolean>;
  isCollapsed: boolean;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
}

const initialState: BarPlot = {
  plotType: PlotType.bar,
  dataRange: undefined,
  orientation: new ValueTracker("Vertical"),
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  xAxisScale: "",
  yAxisScale: "",
  primaryInterval: 95,
  secondInterval: 1,
  bootstrapSamples: new ValueTracker(1000),
  seed: new ValueTracker(0),
  estimator: "",
  errorBar: "--None--",
  isCollapsed: true,
  hasHeaders: true,
};

const designInitialState: BarDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker("#1f77b4"),
  fillStyle: "One Color",
  palette: new ValueTracker("Tab10"),
  colorOrder: "",
  width: new ValueTracker(0.8),
  errorBarWidth: new ValueTracker(2),
  errorBarCapWidth: new ValueTracker(0.5),
  errorColor: new ValueTracker("#000000"),
  dodge: new ValueTracker(true),
  isCollapsed: true,
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0),
  rightPosition: new ValueTracker(0),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
};

export const PlotContext = createContext<BarPlotContext>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const useBar = () => {
  const context = useContext<BarPlotContext>(PlotContext);
  return context;
};

export const BarPlotProvider = ({ children }: { children: React.ReactNode }) => {
  // TODO: swap the names `updatePlot` and `setPlot`. They are currently backwards
  //       due to the rolling refactor of all the chart types.
  const [plot, setPlot] = useState<BarPlot>(initialState);
  const [design, setDesign] = useState<BarDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <PlotContext.Provider
      value={{
        plot,
        updatePlot,
        design,
        updateDesign,
        resetContext,
        updatePlotWithReset,
      }}
    >
      {children}
    </PlotContext.Provider>
  );
};
