import React, { ComponentType } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  CounterBadge,
  Spinner,
} from "@fluentui/react-components";
import { Person20Regular, PeopleCommunity20Regular, People20Regular } from "@fluentui/react-icons";
import { Text } from "@fluentui/react-components";
import { Project, SupportedTags } from "../../../data/projects/models";
import { useAccount } from "../../../queryclient/account/account";
import { ProjectFolder, ProjectItemProps } from "./ProjectFolder";
import { selectMyProjects, selectUserProjects, selectOrgProjects, tagToTitle } from "../../../data/projects/utils";

const iconMap = {
  mine: <Person20Regular />,
  org: <PeopleCommunity20Regular />,
  user: <People20Regular />,
};

function ProjectGroup({
  id,
  tag,
  title,
  icon,
  projects,
  projectsLoading,
  Item,
  refreshItems,
  startExpanded,
  dataTestId,
}: {
  id: string;
  tag: SupportedTags;
  title: string;
  icon: "mine" | "org" | "user";
  projects: Project[];
  projectsLoading?: boolean;
  Item: ComponentType<ProjectItemProps>;
  refreshItems?: () => Promise<void>;
  startExpanded?: boolean;
  dataTestId?: string;
}) {
  return (
    <Accordion key={id} collapsible defaultOpenItems={startExpanded ? "1" : ""}>
      <AccordionItem value="1" style={{ position: "relative" }}>
        <AccordionHeader size="medium" expandIconPosition="start" icon={iconMap[icon]}>
          <Text data-testid={dataTestId} style={{ marginRight: 2 }}>
            {title}
          </Text>
          {projectsLoading ? (
            <CounterBadge dot size="small" color="informative" />
          ) : (
            <CounterBadge
              data-testid={`${dataTestId}-count`}
              count={projects.length}
              size="small"
              color="informative"
              showZero={true}
            />
          )}
        </AccordionHeader>
        <AccordionPanel>
          {projectsLoading ? (
            <Spinner style={{ marginTop: 10 }} size="tiny" label="Loading..." />
          ) : (
            projects.map((project) => (
              <ProjectFolder
                tag={tag}
                key={project.id}
                Item={Item}
                project={project}
                refreshItems={refreshItems}
                startExpanded={startExpanded}
              />
            ))
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export function ProjectGroups({
  tag,
  projects,
  projectsLoading,
  Item,
  refreshItems,
  startExpanded,
}: {
  tag: SupportedTags;
  projects: Project[];
  projectsLoading?: boolean;
  Item: ComponentType<ProjectItemProps>;
  refreshItems?: () => Promise<void>;
  startExpanded?: boolean;
}) {
  const { data: account, isLoading: isLoadingAccount } = useAccount();
  return (
    <>
      <ProjectGroup
        id="my-projects"
        tag={tag}
        title={`My ${tagToTitle(tag)}s`}
        dataTestId="my-projects"
        icon="mine"
        projects={selectMyProjects(projects, account)}
        projectsLoading={projectsLoading || isLoadingAccount}
        Item={Item}
        refreshItems={refreshItems}
        startExpanded={startExpanded}
      />
      <ProjectGroup
        id="user-owned-projects"
        tag={tag}
        title={`User ${tagToTitle(tag)}s`}
        dataTestId="user-owned-projects"
        icon="user"
        projects={selectUserProjects(projects, account)}
        projectsLoading={projectsLoading || isLoadingAccount}
        Item={Item}
        refreshItems={refreshItems}
        startExpanded={startExpanded}
      />
      <ProjectGroup
        id="org-owned-projects"
        tag={tag}
        title={`Organization ${tagToTitle(tag)}s`}
        icon="org"
        projects={selectOrgProjects(projects)}
        projectsLoading={projectsLoading || isLoadingAccount}
        Item={Item}
        refreshItems={refreshItems}
        startExpanded={startExpanded}
      />
    </>
  );
}
