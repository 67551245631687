import React, { CSSProperties } from "react";
import SelectSearch, { SelectSearchProps } from "react-select-search";
import { Row } from "./Layout/Space";
import "./SearchDropdown.scss";

/**
 * Exmaple Usage:
 * The options array should contain objects.
 * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
    const options = [
    { name: "Swedish", value: "sv" },
    { name: "English", value: "en" },
    {
        type: "group",
        name: "Group name",
        items: [{ name: "Spanish", value: "es" }],
    },
    ];
*/

type SearchDropdownProps = SelectSearchProps & {
  onClick?: () => void;
  Icon?: React.ReactElement;
  style?: CSSProperties;
  transparent?: boolean;
  "data-testid": string;
};

export function SearchDropdown({
  onClick,
  placeholder,
  value,
  options,
  onChange,
  Icon,
  style,
  transparent,
  ...props
}: SearchDropdownProps) {
  return (
    <div className={`search-dropdown ${transparent ? "light" : ""}`} style={style} onClick={onClick}>
      <Row alignItems="center" gap={8}>
        {Icon}
        <SelectSearch
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          value={value}
          autoComplete="on"
          fuzzySearch={true}
          search={true}
          emptyMessage="No results"
          {...props}
        />
      </Row>
    </div>
  );
}
