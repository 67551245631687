import React, { useRef } from "react";
import { Button, Tooltip } from "@fluentui/react-components";
import { DocumentCopyRegular, Play16Filled } from "@fluentui/react-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Row } from "../../components/Layout/Space";
import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import { editorSetup } from "xlcommon/src/pyscript/editor";
import { codeDarkTheme } from "../../theme";
import {
  RangeType,
  getAliasFromRangeSelection,
  writeToCell,
  getSplitIdAddrFromBinding,
} from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation } from "../../../excel/grid-utils";

export const SnippetViewer = ({ code, name }: { code: string; name: string }) => {
  const stateCode = useRef<string>("");

  async function addCodeToContainer(code: string) {
    stateCode.current = code;
    const options = { promptText: "Click to select" };
    Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, options, promptCallback);
  }

  async function promptCallback(result) {
    if (result.value === undefined) {
      console.log("User cancelled the dialog");
      return;
    }

    let code = stateCode.current;

    const targetAddress = await getAliasFromRangeSelection({
      rangeType: RangeType.CellBinding,
      identifier: result.value.id,
    });
    // Easy way to delete the binding id as a side effect
    await getSplitIdAddrFromBinding(result.value.id, /*deleteId=*/ true);

    if (name.endsWith(".py")) {
      await writeToExecutionLocation(targetAddress, code);
    } else {
      await writeToCell(targetAddress, code, false);
    }
  }

  return (
    <>
      <CodeMirror
        editable={false}
        height="100%"
        value={code}
        basicSetup={editorSetup}
        theme={codeDarkTheme}
        extensions={[python()]}
        data-testid="code-snippet-viewer"
      />
      <div style={{ paddingTop: 8, paddingBottom: 8 }}>
        <Row justifyContent="flex-end" gap={10}>
          <Tooltip content="Copy code" relationship="label" withArrow>
            <div>
              {" "}
              {/* This div is necessary to prevent the Tooltip from conflicting with CopyToClipboard component listeners simply */}
              <CopyToClipboard text={code}>
                <Button appearance="subtle" icon={<DocumentCopyRegular />} />
              </CopyToClipboard>
            </div>
          </Tooltip>
          <Tooltip content="Run in cell" relationship="label" withArrow>
            <Button
              className="action-button primary"
              onClick={async () => await addCodeToContainer(code)}
              appearance="primary"
              icon={<Play16Filled />}
              iconPosition="before"
            />
          </Tooltip>
        </Row>
      </div>
    </>
  );
};
