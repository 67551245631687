import React, { useContext } from "react";
import ChartDropdown from "./VizSharedComponents/Dropdowns";
import BarForm from "./Plots/BarPlotMVC";
import CountForm from "./Plots/CountPlotMVC";
import BoxForm from "./Plots/BoxPlotMVC";
import ScatterForm from "./Plots/ScatterPlotMVC";
import LineForm from "./Plots/LinePlotMVC";
import RegressionForm from "./Plots/RegressionPlotMVC";
import ViolinForm from "./Plots/ViolinPlotMVC";
import { useNavigate, useParams } from "react-router-dom";
import ActionButtons from "./ActionButtons";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { buildPythonInExcelCode } from "./MVCShared/CodeBuilder";
import { useGeneratedCodeContext } from "../../hooks/useCode";
import { Context } from "./Code";
import PairForm from "./Plots/PairPlot";
import DisplotForm from "./Plots/Displot";
import { writeToExecutionLocation } from "../../../excel/grid-utils";

const FormComponentMap = {
  bar: BarForm,
  box: BoxForm,
  count: CountForm,
  line: LineForm,
  regression: RegressionForm,
  scatter: ScatterForm,
  violin: ViolinForm,
  pairwise: PairForm,
  distribution: DisplotForm,
};

const BaseSetup = () => {
  const navigate = useNavigate();
  const params = useParams();
  const plotType = params?.plotType;
  const contextType = Context[params.plotType];
  const { generatedCode, clearGeneratedCode } = useGeneratedCodeContext();
  const { plot, resetContext } = useContext<typeof contextType>(contextType);

  const handleChange = (_, data: string) => {
    navigate(`/viz/${data}/setup`);
  };

  const isDisabled = !plot?.dataRange || plot.outputCell.displayName === undefined;

  const handleRestart = async () => {
    await resetContext();
    clearGeneratedCode();
  };
  const handleCreateClick = async () => {
    const addr = await getFullAddressFromRangeSelection(plot.outputCell);
    const code = await buildPythonInExcelCode(generatedCode, false);
    await writeToExecutionLocation(addr, code);
  };

  const SelectedFormComponent = FormComponentMap[plotType];

  return (
    <div className="base-setup" style={{ paddingBottom: 120 }}>
      <ChartDropdown value={plotType} onChange={handleChange} />
      <SelectedFormComponent />
      <ActionButtons onCreateClick={handleCreateClick} onRestart={handleRestart} disabled={isDisabled} />
    </div>
  );
};

export default BaseSetup;
