// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "#0cca4a",
	"secondary": "#6d5bf6",
	"notificationErrorBg": "#dc3246",
	"appBg": "#fff",
	"baseBg": "#f3f4f6",
	"baseText": "#2c303a",
	"disabled": "#bdbdbd",
	"gray100": "#f9fafb",
	"gray200": "#ced2d9",
	"gray300": "#abb1bf",
	"gray400": "#8c94a6",
	"gray500": "#6a718a",
	"gray600": "#52596b",
	"gray800": "#353b41"
};
export default ___CSS_LOADER_EXPORT___;
