import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export enum PlotType {
  bar = "bar",
  line = "line",
  box = "box",
  regression = "regression",
  scatter = "scatter",
  violin = "violin",
  count = "count",
  pairwise = "pairwise",
  distribution = "distribution",
}

export enum PlotLibrary {
  seaborn = "seaborn",
  plotly = "plotly",
}

// I don't know if this should be here, a few plots can use a facet grid I didn't want to duplicate anything
export interface FacetGridPlot {
  shareX: ValueTracker<boolean>;
  shareY: ValueTracker<boolean>;
  dropna: ValueTracker<boolean>;
}

export interface FacetGridDesign {
  xlim: ValueTracker<number>;
  ylim: ValueTracker<number>;
  facetPalette?: ValueTracker<string>;
}

export interface BasePlot extends Partial<FacetGridPlot> {
  plotType?: PlotType;
  dataRange?: RangeSelection;
  headers: string[];
  xAxis?: string;
  yAxis?: string;
  colorBy?: string;
  orientation?: ValueTracker<string>;
  outputCell?: RangeSelection;
  xAxisScale?: string;
  yAxisScale?: string;
  hasHeaders: boolean;
}

export interface BaseDesign extends Partial<FacetGridDesign> {
  plotTitle?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  fill?: ValueTracker<string>;
  fillStyle?: string;
  palette?: ValueTracker<string>;
}

export interface BasePlotContext<T, K> {
  plot: T;
  design: K;
  updatePlot: (updates: Record<string, any>, resetValue?: any) => void; //React.Dispatch<React.SetStateAction<T>>;
  updateDesign: (updates: Record<string, any>, resetValue?: any) => void; //React.Dispatch<React.SetStateAction<K>>;
  updatePlotWithReset: (updates: Record<string, any>) => void;
  resetContext: () => void;
}

export function handleDeselectionInUpdates(
  updates: Record<string, any>,
  initial: Record<string, any>,
  resetValue: string
) {
  Object.keys(updates).forEach((key: string) => {
    if (updates[key] === resetValue) {
      if (initial[key] instanceof ValueTracker) {
        updates[key] = initial[key].reset();
      } else {
        updates[key] = initial[key];
      }
    }
  });
}

export function rebuildFromUpdates<T>(base: T, updates: Record<string, any>): T {
  return {
    ...base,
    ...Object.fromEntries(
      Object.entries(updates).map(([k, v]) => {
        // Check for incorrect passing of existing base structure into rebuildFromUpdates.
        // Doing so will include ValueTracker objects as update values, which should never happen.
        // All updates `v` should be normal values.
        if (v instanceof ValueTracker) throw Error(`Attempting to update ${k} with ValueTracker`);
        // If `k` is already a ValueTracker within `base`, update it with the new `v`
        if (base[k] instanceof ValueTracker) {
          v = base[k].update(v);
        }
        return [k, v];
      })
    ),
  };
}
