import React from "react";
import { ChevronRightFilled, Info16Filled } from "@fluentui/react-icons";
import { Checkbox, Text, Tooltip } from "@fluentui/react-components";
import { Row } from "../../../components/Layout/Space";
import styles from "../../../styles.module.scss";

const ImportPyObjectCheckbox = ({
  onChecked,
  checked,
  isDisabled,
}: {
  checked: boolean;
  onChecked: () => void;
  isDisabled: boolean;
}) => {
  return (
    <Checkbox
      label={
        <Row alignItems="center">
          <Text style={{ position: "absolute", left: 30 }}>
            Import as PyScript Code object
            <Tooltip
              content={
                <>
                  Dataset will be imported as a PyScript object and will be accessible with =PYSCRIPT.CODE(). We
                  recommend this for large datasets. To enable, select Generate Toolbox Code As =PYSCRIPT.CODE() under{" "}
                  <u>
                    Anaconda Toolbox <ChevronRightFilled style={{ verticalAlign: "middle" }} /> Settings.
                  </u>
                </>
              }
              relationship="description"
            >
              <Info16Filled
                color={styles.gray600}
                style={{ verticalAlign: "middle", marginLeft: 8, paddingBottom: 2 }}
              />
            </Tooltip>
          </Text>
        </Row>
      }
      checked={checked}
      onClick={onChecked}
      disabled={isDisabled}
    />
  );
};

export default ImportPyObjectCheckbox;
