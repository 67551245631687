import React from "react";
import { Divider, Tooltip, Checkbox } from "@fluentui/react-components";
import { CustomDropdown, DesignInputs, CollapsibleSection } from "../VizSharedComponents/VizUIShared";
import { ChartAttr, UIAttr, Dependency, areDependenciesSatisfied, ValueTracker } from "./types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import TableChooser from "../../../../excel/components/TableChooser";
import { Row } from "../../../components/Layout/Space";
import AddressChooser from "../../../../excel/components/AddressChooser";
import { BasePlot } from "src/taskpane/hooks/plots/PlotTypes";
import { PaletteDropdown } from "../VizSharedComponents/Palette";
import { Spinner } from "../VizSharedComponents/SpinButton";
import VizColorPicker from "../VizSharedComponents/ColorPicker";
import { buildReactFromAttrs, buildCodeFromAttrs, CodeBuilder } from "./CodeBuilder";
import { snakeEyesRecord } from "../../../../analytics/snake-eyes";

/****************
 * UIAttr Constructors
 ****************/
export function DividerAttr(): UIAttr {
  return {
    getReact: () => {
      return <Divider />;
    },
  };
}

type HeadingArgs = {
  title: string;
  tooltip?: string;
  visibleDependencies?: Dependency[];
};

export function HeadingAttr(args: HeadingArgs): UIAttr {
  const { title, tooltip, visibleDependencies } = args;

  const headingLabel = tooltip ? (
    <div style={{ marginTop: 5 }}>
      <Tooltip content={tooltip} positioning="below-start" relationship="label" withArrow={true}>
        <label className="label">{title}</label>
      </Tooltip>
    </div>
  ) : (
    <div style={{ marginTop: 5 }}>
      <label className="label">{title}</label>
    </div>
  );

  return {
    visibleDependencies: visibleDependencies,
    getReact: () => {
      return <>{headingLabel}</>;
    },
  };
}

export type DropdownArgs = {
  value?: string | ValueTracker<string> | string[];
  onChange: (event, data) => void;
  label?: string;
  options?: string[];
  placeholder?: string;
  codeKey?: string;
  callKey?: string;
  codeValueMap?: Record<string, string>;
  multiselect?: boolean;
  selectedOptons?: string[];
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  dataTestID?: string;
};

export function DropdownAttr(args: DropdownArgs): ChartAttr {
  const { value, placeholder = "--Select-- ", codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: () => {
      return (
        <CustomDropdown
          dataTestID={args.dataTestID}
          label={args.label}
          options={args.options}
          placeholder={placeholder}
          value={val}
          onChange={args.onChange}
          multiselect={args.multiselect}
          isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
          selectedOptions={args.selectedOptons}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("DropdownAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      let selections = "";

      if (Array.isArray(val)) {
        val.forEach((selection) => {
          selections += `"${selection}", `;
        });

        if (selections.length > 1) {
          code.plotAttrs.push(`${args.codeKey}=[${selections}]`);
        }
      } else {
        let codeValue = args.codeValueMap ? args.codeValueMap[val] : val;
        codeValue = (codeValue as string) ?? codeValue?.replaceAll("'", "\\'"); // escape single quotes
        codeValue && args.codeKey ? code.plotAttrs.push(`${args.codeKey}='${codeValue}'`) : "";
        codeValue && args.callKey ? code.plotCalls.push(`plt.${args.callKey}('${codeValue}')`) : "";
      }
    },
  };
}

export type AxisDropdown = DropdownArgs & {
  hasHeaders: boolean;
};

export function MultiSelectDropdownAttr(args: AxisDropdown): ChartAttr {
  const { value } = args;
  let val = value instanceof ValueTracker ? value.value : (value as any);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: () => {
      return (
        <CustomDropdown
          dataTestID={args.dataTestID}
          label={args.label}
          options={args.options}
          placeholder="--Select-- "
          value={val}
          onChange={args.onChange}
          multiselect={args.multiselect}
          selectedOptions={args.selectedOptons}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      let selections = "";
      if (Array.isArray(val)) {
        if (!args.hasHeaders) {
          val.forEach((selection) => {
            let column = `${parseInt(selection.split(" ")[1]) - 1}, `;
            selections += `${column}`;
          });
        } else {
          val.forEach((selection) => {
            selections += `"${selection}", `;
          });
        }
        if (selections.length > 1) {
          code.plotAttrs.push(`${args.codeKey}=[${selections}]`);
        }
      }
    },
  };
}

export function AxisDropdownAttr(args: AxisDropdown): ChartAttr {
  const { value, onChange, placeholder = "--Select--", codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: () => {
      return (
        <CustomDropdown
          dataTestID={args.dataTestID}
          label={args.label}
          options={args.options}
          value={val}
          onChange={onChange}
          placeholder={placeholder}
          multiselect={args.multiselect}
          isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("AxisDropdownAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      let codeValue = args.codeValueMap ? args.codeValueMap[val] : val;
      if (!args.hasHeaders && codeValue) {
        const column = parseInt(codeValue.split(" ")[1]);
        code.plotAttrs.push(`${args.codeKey}=${column - 1}`);
      } else {
        codeValue && args.codeKey ? code.plotAttrs.push(`${args.codeKey}='${codeValue}'`) : "";
      }
    },
  };
}

export type LabelArgs = DropdownArgs & {
  posKey?: string;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

export function LabelAttr(args: LabelArgs): ChartAttr {
  const { value, onChange: onInput, codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: () => {
      return (
        <DesignInputs
          dataTestID={args.dataTestID}
          label={args.label}
          value={val}
          onInput={onInput}
          placeholder={args.placeholder}
          isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker)) throw Error("LabelAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      // TODO: change this to callKey and add new line to handle codeKey
      // TODO: review all the other ChartAttrs and unify the usage of codeKey, callKey, codeValueMap
      val = val.replaceAll("'", "\\'"); // Escape single quotes
      args.posKey && val ? code.plotAttrs.push(`${args.posKey}='${val}'`) : "";
      args.codeKey && val ? code.plotCalls.push(`plt.${args.codeKey}('${val}')`) : "";
    },
  };
}

type DataRangeArgs = {
  dataRange: BasePlot["dataRange"];
  onChangeSelection: (newSelection: RangeSelection) => void;
  visibleDependencies?: Dependency[];
};

export function DataRangeAttr(args: DataRangeArgs): UIAttr {
  const { dataRange, onChangeSelection, visibleDependencies } = args;
  return {
    visibleDependencies: visibleDependencies,
    getReact: () => {
      return (
        <Row gap={5} justifyContent="space-between">
          <label className="label">Data Range:</label>
          <TableChooser
            selection={dataRange}
            onSelection={(newSelection) => {
              onChangeSelection(newSelection);
              snakeEyesRecord({
                event: "viz/range_selected",
              });
            }}
          />
        </Row>
      );
    },
  };
}

type OutputArgs = {
  outputCell: RangeSelection;
  onChange: (event) => void;
  visibleDependencies?: Dependency[];
};

export function OutputAttr(args: OutputArgs): UIAttr {
  const { outputCell, onChange, visibleDependencies } = args;
  return {
    visibleDependencies: visibleDependencies,
    getReact: () => {
      return (
        <Row justifyContent="space-between">
          <AddressChooser selection={outputCell} onSelection={onChange} />
        </Row>
      );
    },
  };
}

type CollapsibleArgs = {
  visibleDependencies?: Dependency[];
  children?: UIAttr[];
  codeKey?: string;
  val?: string;
  codeValueMap?: Record<string, string>;
  collapsed?: boolean;
  toggle?: () => void;
};

export function CollapsibleAttr(args: CollapsibleArgs): UIAttr {
  const { visibleDependencies, children, collapsed, toggle } = args;
  return {
    visibleDependencies: visibleDependencies,
    getReact: () => {
      return (
        <>
          <CollapsibleSection isCollapsed={collapsed} toggleCollapse={toggle}>
            {buildReactFromAttrs(children)}
          </CollapsibleSection>
        </>
      );
    },
    getCode: (cb: CodeBuilder) => {
      buildCodeFromAttrs(children, cb);
    },
  };
}

const paletteValueMap = {
  Accent: "Accent",
  Blues: "Blues",
  BrBG: "BrBG",
  Blues_d: "Blues_d",
  Tab10: "tab10",
};

export function PaletteAttr(args: DropdownArgs): ChartAttr {
  const {
    value,
    onChange,
    codeKey,
    visibleDependencies,
    codeRequiresInteraction,
    enabledDependencies,
    label = "Palette",
  } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);

  return {
    component: val,
    visibleDependencies: visibleDependencies,
    enabledDependencies: enabledDependencies,
    getReact: () => {
      return (
        <PaletteDropdown
          dataTestID={args.dataTestID}
          value={val}
          onChange={onChange}
          disabled={!areDependenciesSatisfied(enabledDependencies)}
          label={label}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      const paletteValue = paletteValueMap[val];
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("PalleteAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      codeKey ? code.plotAttrs.push(`${codeKey}='${paletteValue}'`) : null;
    },
  };
}

type CheckboxArgs = {
  value?: boolean | ValueTracker<boolean>;
  onChange: (_, event) => void;
  label: string;
  codeKey?: string;
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  dataTestID?: string;
};

export function CheckBoxAttr(args: CheckboxArgs): ChartAttr {
  const { value, onChange, label, codeKey, codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : value;
  return {
    component: val,
    visibleDependencies: args.visibleDependencies || [],
    enabledDependencies: args.enabledDependencies,
    getReact: () => {
      return (
        <Row justifyContent="space-between" gap={5}>
          <label className="label">{label}</label>
          <Checkbox
            checked={val}
            onChange={onChange}
            disabled={!areDependenciesSatisfied(args.enabledDependencies)}
            data-testid={args.dataTestID}
          />
        </Row>
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("CheckBoxAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      let sval: string = val ? "True" : "False";
      codeKey ? code.plotAttrs.push(`${codeKey}=${sval}`) : null;
    },
  };
}

type SpinnerArgs = {
  value?: number | ValueTracker<number>;
  suffix?: string;
  onChange: (_, event) => void;
  label: string;
  step: number;
  max?: number;
  min?: number;
  codeKey?: string;
  callKey?: string;
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  dataTestID?: string;
};

export function SpinnerAttr(args: SpinnerArgs): ChartAttr {
  const {
    value,
    onChange,
    label,
    visibleDependencies,
    enabledDependencies,
    codeKey,
    callKey,
    step,
    max,
    min,
    codeRequiresInteraction,
  } = args;
  let val = value instanceof ValueTracker ? value.value : value;
  return {
    component: val,
    visibleDependencies: visibleDependencies,
    enabledDependencies: enabledDependencies,
    getReact: () => {
      return (
        <Spinner
          dataTestID={args.dataTestID}
          label={label}
          value={val}
          onChange={onChange}
          step={step}
          max={max}
          min={min}
          suffix={args.suffix}
          isDisabled={!areDependenciesSatisfied(enabledDependencies)}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("SpinnerAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      codeKey ? code.plotAttrs.push(`${codeKey}=${val}`) : null;
      callKey ? code.plotCalls.push(`plt.${callKey}(rotation=${val})`) : null;
    },
  };
}

type ColorPickerArgs = {
  value: string | ValueTracker<string>;
  onChange: (_, event) => void;
  codeKey?: string;
  visibleDependencies?: Dependency[];
  label: string;
  codeRequiresInteraction?: boolean;
};

export function ColorPickerAttr(args: ColorPickerArgs): ChartAttr {
  const { value, onChange, visibleDependencies, codeKey, label, codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : value;
  return {
    component: val,
    visibleDependencies: visibleDependencies,
    getReact: () => {
      return <VizColorPicker label={label} defaultColor={val} onChange={onChange} />;
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("ColorPickerAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      codeKey ? code.plotAttrs.push(`${codeKey}='${val}'`) : "";
    },
  };
}

type LineKwargsArgs = {
  dropdownValue: string | ValueTracker<string>;
  onChangeStyle: (_, event) => void;
  styleLabel: string;
  options?: string[];
  codeValueMap?: Record<string, string>;
};

export function LineKwargs(args: SpinnerArgs & LineKwargsArgs): ChartAttr {
  let val = args.value instanceof ValueTracker ? args.value.value : args.value;
  let dropdownVal = args.dropdownValue instanceof ValueTracker ? args.dropdownValue.value : args.dropdownValue;
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: () => {
      return (
        <>
          <CustomDropdown
            label={args.styleLabel}
            options={args.options}
            value={dropdownVal}
            onChange={args.onChangeStyle}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
          />
          <Spinner
            label={args.label}
            value={val}
            onChange={args.onChange}
            step={args.step}
            suffix={args.suffix}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
          />
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      function checkValueAndUpdate(args, propName) {
        if (!(args[propName] instanceof ValueTracker)) {
          throw new Error(`${propName} must use ValueTracker if codeRequiresInteraction`);
        }
        if (!args[propName].isUpdated) {
          return false;
        }
        return true;
      }
      if (args.codeRequiresInteraction) {
        if (!checkValueAndUpdate(args, "value") && !checkValueAndUpdate(args, "dropdownValue")) return;
      }
      const lineKws = {};
      dropdownVal ? (lineKws["ls"] = args.codeValueMap[dropdownVal]) : "";
      val ? (lineKws["lw"] = val) : "";
      Object.keys(lineKws).length > 0
        ? code.plotAttrs.push(
            `line_kws={${Object.entries(lineKws)
              .map(([key, value]) => `'${key}': ${JSON.stringify(value)}`)
              .join(", ")}}`
          )
        : null;
    },
  };
}

type errorBarSpinner = {
  spinnerValue: number | ValueTracker<number>;
  decimalValue: number | ValueTracker<number>;
  suffixLabel: string;
  decimalLabel: string;
  suffixStep: number;
  decimalStep: number;
  max?: number;
  suffix?: string;
  xAxis: string;
  yAxis: string;
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  onValueChange: (_, value) => void;
  onDecimalChange: (_, value) => void;
};

export function regressionErrorBar(args: ColorPickerArgs & errorBarSpinner): ChartAttr {
  const val = args.value instanceof ValueTracker ? args.value.value : args.value;
  const suffixVal = args.spinnerValue instanceof ValueTracker ? args.spinnerValue.value : args.spinnerValue;
  const decimalVal = args.decimalValue instanceof ValueTracker ? args.decimalValue.value : args.decimalValue;
  return {
    component: "Error Bar",
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: () => {
      return (
        <>
          <VizColorPicker defaultColor={val} label={args.label} onChange={args.onChange} />
          <Spinner
            label={args.suffixLabel}
            value={suffixVal}
            onChange={args.onValueChange}
            step={args.suffixStep}
            suffix={args.suffix}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
            dataTestID="errwidth"
          />
          <Spinner
            label={args.decimalLabel}
            value={decimalVal}
            onChange={args.onDecimalChange}
            step={args.decimalStep}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
            dataTestID="capsize"
          />
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      let start = `ax.errorbar(x="${args.xAxis}", y="${args.yAxis}"`;
      let anyUpdated = false;

      if (args.codeRequiresInteraction) {
        if (args.value instanceof ValueTracker && args.value.isUpdated) {
          start += `, ecolor='${val}'`;
          anyUpdated = true;
        }

        if (args.spinnerValue instanceof ValueTracker && args.spinnerValue.isUpdated) {
          start += `, capthick=${suffixVal}`;
          anyUpdated = true;
        }

        if (args.decimalValue instanceof ValueTracker && args.decimalValue.isUpdated) {
          start += `, capsize=${decimalVal}`;
          anyUpdated = true;
        }
      }
      if (anyUpdated && args.xAxis && args.yAxis) {
        start += ")";
        code.plotCalls.push(start);
      }
    },
  };
}

type LegendArgs = DropdownArgs & {
  topValue: number | ValueTracker<number>;
  rightValue: number | ValueTracker<number>;
  firstDecimalChange?: (_, value) => void;
  secondDecimalChange?: (_, value) => void;
  topLabel?: string;
  rightLabel?: string;
};

export function LegendAttr(args: LegendArgs): ChartAttr {
  const { value, onChange, callKey, visibleDependencies, enabledDependencies, label } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  let topVal = args.topValue instanceof ValueTracker ? args.topValue.value : args.topValue;
  let rightVal = args.rightValue instanceof ValueTracker ? args.rightValue.value : args.rightValue;
  return {
    component: val,
    visibleDependencies: visibleDependencies,
    enabledDependencies: enabledDependencies,
    getReact: () => {
      return (
        <>
          <CustomDropdown
            label={label}
            placeholder="--Select--"
            options={[
              "--Select--",
              "Best",
              "Upper Right",
              "Upper Left",
              "Lower Right",
              "Lower Left",
              "Right",
              "Center Left",
              "Center Right",
              "Lower Center",
              "Upper Center",
            ]}
            value={val}
            onChange={onChange}
            isDisabled={!areDependenciesSatisfied(enabledDependencies)}
          />
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      let codeValue = args.codeValueMap ? args.codeValueMap[val] : val;
      const box_location = `bbox_to_anchor=(${rightVal}, ${topVal})`;
      const bbox_condition =
        args.topValue instanceof ValueTracker &&
        args.topValue.isUpdated &&
        args.rightValue instanceof ValueTracker &&
        args.rightValue.isUpdated;

      if (bbox_condition) {
        codeValue && callKey ? code.plotCalls.push(`sns.${callKey}(ax,'${codeValue}', ${box_location})`) : "";
      } else {
        codeValue && callKey ? code.plotCalls.push(`sns.${callKey}(ax,'${codeValue}')`) : "";
      }
    },
  };
}
