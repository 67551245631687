import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TabList, Toolbar, ToolbarGroup } from "@fluentui/react-components";
import { NavBarItems } from "./nav-models";
import { Logo } from "../Logo";
import { TooltipTab } from "../TooltipTab";
import { CollapsedNavMenu } from "./NavMenu";
import { usePython } from "../../hooks/usePython";
import "./TopBar.scss";
import Env from "xlcommon/src/environ";

export const TopBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPython } = usePython();

  const selectedValue: string = "/" + location.pathname.split("/")[1];

  const [title, setTitle] = useState<string>("Anaconda Toolbox");
  useEffect(() => {
    if (location.pathname.length > 0) {
      if (selectedValue === "/") {
        setTitle("Anaconda Toolbox");
      } else {
        // Find matching NavMenuItem
        for (let item of NavBarItems) {
          if (item.path === selectedValue) {
            setTitle(item.title);
            break;
          }
        }
      }
    }
  }, [location.pathname]);

  return (
    <Toolbar className="toolbar" aria-label="tab bar">
      <ToolbarGroup>
        <Logo title={title} />
      </ToolbarGroup>
      <ToolbarGroup>
        <CollapsedNavMenu />
        <TabList
          className="tab-list"
          style={{ marginRight: 14 }}
          selectedValue={selectedValue}
          appearance="subtle"
          // Tab value must match route path
          onTabSelect={(_, data) => navigate(data.value)}
        >
          {NavBarItems.map((item) => {
            const disabled = item.disabled || (item.requiresPython && !hasPython && !Env.UNLOCKED);
            return (
              <TooltipTab
                key={item.title}
                tooltipContent={item.title}
                icon={<item.Icon />}
                value={item.path}
                disabled={disabled}
                className={disabled ? "tab disabled" : selectedValue === item.path ? "tab active" : "tab"}
              >
                {item.title}
              </TooltipTab>
            );
          })}
        </TabList>
      </ToolbarGroup>
    </Toolbar>
  );
};

export default TopBar;
