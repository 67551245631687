import React from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { CustomDropdown } from "./VizUIShared";
import "../Viz.scss";

interface ColorBlockProps {
  color: string;
}

const ColorBlock: React.FC<ColorBlockProps> = ({ color }) => (
  <span className="color-block" style={{ backgroundColor: color }} />
);

const PaletteOptions: React.FC<{ colors: string[]; label: string }> = ({ colors, label }) => {
  return (
    <span>
      <label className="palette-label">{label}</label>
      <span className="color-blocks">
        {colors.map((color, index) => (
          <ColorBlock key={index} color={color} />
        ))}
      </span>
    </span>
  );
};

const paletteOptions: IDropdownOption[] = [
  { key: "Tab10", text: "Tab10", colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"] },
  {
    key: "Accent",
    text: "Accent",
    colors: ["#90C686", "#BBAFD2", "#F4C28D", "#FEFFA6", "#456BAA", "#DC307E", "#B3612B", "#656666"],
  },
  { key: "Blues", text: "Blues", colors: ["#DEE9F5", "#BFD6E9", "#94BEDA", "#669CC9", "#4179B5", "#25549A"] },
  { key: "Blues_d", text: "Blues_d", colors: ["#394A5A", "#3F6382", "#4979A8", "#5C90C1", "#77A3CB", "#94BAD9"] },
  { key: "BrBG", text: "BrBG", colors: ["#99682B", "#D5BA7C", "#F4ECD4", "#D9ECE9", "#89C4BA", "#397872"] },
].map(({ key, text, colors }) => ({
  key,
  text,
  data: {
    component: <PaletteOptions label={text} colors={colors} />,
  },
}));

type PaletteProps = {
  value: string;
  onChange?: (e, data) => void;
  options?: IDropdownOption[];
  dataTestID?: string;
  disabled?: boolean;
  label: string;
};

export const PaletteDropdown: React.FC<PaletteProps> = ({ onChange, value, dataTestID, disabled, label }) => {
  return (
    <CustomDropdown
      options={paletteOptions}
      label={label}
      value={value}
      onChange={onChange}
      dataTestID={dataTestID}
      isDisabled={disabled}
    ></CustomDropdown>
  );
};
