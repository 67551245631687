import React, { createContext, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";
import {
  BaseDesign,
  BasePlot,
  BasePlotContext,
  PlotType,
  rebuildFromUpdates,
  handleDeselectionInUpdates,
} from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface PairPlotContextType extends BasePlotContext<PairPlot, PairDesign> {
  // TODO: specifics
}

interface PairPlot extends BasePlot {
  // TODO: specifics
  corner: ValueTracker<boolean>;
  kind: ValueTracker<string>;
  diagKind: ValueTracker<string>;
  xVars: string[];
  yVars: string[];
  vars: string[];
  isCollapsed: boolean;
}

interface PairDesign extends BaseDesign {
  // TODO: specifics
  palette: ValueTracker<string>;
  // Can be single or mulitple
  markers: string[];
  markerSize: ValueTracker<number>;
  lineStyle: ValueTracker<string>;
  lineWidth: ValueTracker<number>;
  height: ValueTracker<number>;
  aspect: ValueTracker<number>;
  bin: ValueTracker<number>;
  element: ValueTracker<string>;
  stat: string;
  isCollapsed: boolean;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
}

const initialState: PairPlot = {
  plotType: PlotType.pairwise,
  dataRange: undefined,
  headers: [],
  xVars: [],
  yVars: [],
  vars: [],
  corner: new ValueTracker(false),
  dropna: new ValueTracker(false),
  colorBy: "",
  kind: new ValueTracker("Scatter"),
  diagKind: new ValueTracker("Auto"),
  outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
  isCollapsed: true,
  hasHeaders: true,
};

const designInitialState: PairDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  palette: new ValueTracker("Accent"),
  markers: [],
  // I don't think matplotlib sets a default marker size
  markerSize: new ValueTracker(6),
  lineStyle: new ValueTracker("Solid"),
  lineWidth: new ValueTracker(3),
  height: new ValueTracker(2.5),
  aspect: new ValueTracker(1),
  bin: new ValueTracker(10),
  element: new ValueTracker("Bars"),
  stat: "",
  isCollapsed: true,
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
};

export const PairPlotContext = createContext<PairPlotContextType>({
  plot: initialState,
  updatePlot: () => Promise<void>,
  design: designInitialState,
  updateDesign: () => Promise<void>,
  resetContext: () => Promise<void>,
  updatePlotWithReset: () => Promise<void>,
});

export const usePair = () => {
  const context = useContext<PairPlotContextType>(PairPlotContext);
  return context;
};

export const PairPlotProvider = ({ children }: { children: React.ReactNode }) => {
  const [plot, setPlot] = useState<PairPlot>(initialState);
  const [design, setDesign] = useState<PairDesign>(designInitialState);

  function updatePlot(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, initialState, resetValue);
    setPlot(rebuildFromUpdates(plot, updates));
  }

  function updateDesign(updates: Record<string, any>, resetValue: any = null) {
    if (resetValue !== null) handleDeselectionInUpdates(updates, designInitialState, resetValue);
    setDesign(rebuildFromUpdates(design, updates));
  }

  const resetContext = () => {
    setPlot({ ...initialState });
    setDesign({ ...designInitialState });
  };

  function updatePlotWithReset(updates: Record<string, any>) {
    setPlot({ ...initialState, ...updates });
    setDesign({
      ...designInitialState,
    });
  }

  return (
    <PairPlotContext.Provider value={{ plot, updatePlot, design, updateDesign, resetContext, updatePlotWithReset }}>
      {children}
    </PairPlotContext.Provider>
  );
};
