import React, { ReactNode } from "react";
import {
  BarPlotProvider,
  BoxPlotProvider,
  CountPlotProvider,
  GeneratedCodeProvider,
  LinePlotProvider,
  RegressionPlotProvider,
  ScatterPlotProvider,
  ViolinPlotProvider,
  PairPlotProvider,
  DisplotProvider,
} from "../hooks";

const VizChartProvider = ({ children }: { children: ReactNode }) => {
  return (
    <BarPlotProvider>
      <BoxPlotProvider>
        <CountPlotProvider>
          <LinePlotProvider>
            <RegressionPlotProvider>
              <ScatterPlotProvider>
                <ViolinPlotProvider>
                  <PairPlotProvider>
                    <DisplotProvider>
                      <GeneratedCodeProvider>{children}</GeneratedCodeProvider>
                    </DisplotProvider>
                  </PairPlotProvider>
                </ViolinPlotProvider>
              </ScatterPlotProvider>
            </RegressionPlotProvider>
          </LinePlotProvider>
        </CountPlotProvider>
      </BoxPlotProvider>
    </BarPlotProvider>
  );
};

export default VizChartProvider;
