import { useEffect } from "react";
import { PlotLibrary, PlotType } from "../../../hooks/plots/PlotTypes";
import { RangeType, RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useCount } from "../../../hooks/plots/useCount";
import { useGeneratedCodeContext } from "../../../hooks/useCode";
import {
  dependencyEqualsValue,
  dependencyNotEqualsValue,
  fetchHeaders,
  onDataRangeChangeSelection,
} from "../MVCShared/PlotGeneratorUtils";
import { Chart, LegendPosition, Orientation } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  AxisDropdownAttr,
  LegendAttr,
  CollapsibleAttr,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode } from "../MVCShared/CodeBuilder";

const CountContext = (): Chart => {
  const { plot, updatePlot, design, updateDesign, updatePlotWithReset } = useCount();
  const { setGeneratedCode } = useGeneratedCodeContext();

  useEffect(() => {
    (async () => {
      let plotCode = await buildCode(countChart, [...countChart.baseAttrs, ...countChart.designAttrs]);
      setGeneratedCode(plotCode);
    })();
  }, [plot, design]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(plot.dataRange, plot.hasHeaders, updatePlot);
    })();
  }, [plot.hasHeaders, plot.dataRange]);

  const orientationDropdown = DropdownAttr({
    value: plot.orientation,
    onChange: (_, data) => updatePlot({ orientation: data.optionValue }),
    label: "Orientation",
    options: ["Horizontal", "Vertical"],
    codeKey: "orient",
    codeValueMap: Orientation,
    codeRequiresInteraction: true,
    dataTestID: "orientation",
  });

  const xAxis = AxisDropdownAttr({
    value: plot.xAxis,
    onChange: (_, data) => {
      updatePlot({ xAxis: data.optionValue }, "--Select--");
      updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
    },
    label: "X-Axis:",
    options: ["--Select--", ...plot.headers],
    codeKey: "x",
    visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Vertical")],
    dataTestID: "x-axis",
    hasHeaders: plot.hasHeaders,
  });

  const yAxis = AxisDropdownAttr({
    value: plot.yAxis,
    onChange: (_, data) => {
      updatePlot({ yAxis: data.optionValue }, "--Select--");
      updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
    },
    label: "Y-Axis:",
    options: ["--Select--", ...plot.headers],
    codeKey: "y",
    visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Horizontal")],
    dataTestID: "y-axis",
    hasHeaders: plot.hasHeaders,
  });

  const fillStyle = DropdownAttr({
    value: design.fillStyle,
    onChange: (_, data) => {
      updateDesign({ fillStyle: data.optionValue });
    },
    label: "Fill Style:",
    options: ["One Color", "Palette"],
    dataTestID: "fill-style",
  });

  const SaturationAttr = SpinnerAttr({
    label: "Saturation:",
    value: design.saturation,
    step: 0.01,
    max: 1,
    onChange: (_, data) => updateDesign({ saturation: data }),
    codeKey: "saturation",
    codeRequiresInteraction: true,
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position:",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const countChart: Chart = {
    plotType: PlotType.count,
    plotLibrary: PlotLibrary.seaborn,
    dataRange: plot.dataRange,
    outputCell: { rangeType: RangeType.CellBinding } as RangeSelection,
    hasHeaders: plot.hasHeaders,
    baseAttrs: [
      orientationDropdown,
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      DataRangeAttr({
        dataRange: plot.dataRange,
        onChangeSelection: (newSelection: RangeSelection) =>
          onDataRangeChangeSelection(newSelection, updatePlotWithReset),
      }),
      CheckBoxAttr({
        label: "Data Has Headers:",
        value: plot.hasHeaders,
        onChange: (_, e) => updatePlot({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      xAxis,
      yAxis,
      AxisDropdownAttr({
        value: plot.colorBy,
        onChange: (_, data) => updatePlot({ colorBy: data.optionValue }, "--None--"),
        label: "Color By:",
        options: ["--None--", ...plot.headers],
        placeholder: "--None--",
        codeKey: "hue",
        hasHeaders: plot.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Output",
        tooltip: "Determine where visualization will render",
      }),
      OutputAttr({
        outputCell: plot.outputCell,
        onChange: (newSelection: RangeSelection) => updatePlot({ outputCell: newSelection }),
      }),
    ],
    designAttrs: [
      HeadingAttr({
        title: "Chart Info",
        tooltip: null,
      }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Plot Title:",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "X-Axis Label:",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
        visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Vertical")],
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "[autofill w/ headers]",
        label: "Y-Axis Label:",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
        visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Horizontal")],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks:",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks:",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Color",
        tooltip: null,
      }),
      fillStyle,
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyEqualsValue(fillStyle, "Palette")],
        codeRequiresInteraction: true,
        dataTestID: "palette",
      }),
      ColorPickerAttr({
        value: design.fill,
        label: "Fill:",
        onChange: (hexColor) => updateDesign({ fill: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(fillStyle, "One Color")],
        codeRequiresInteraction: true,
      }),
      SaturationAttr,
      // DropdownAttr({
      //   value: design.colorOrder,
      //   onChange: (_, data) => {
      //     updateDesign({ colorOrder: data.optionValue });
      //   },
      //   label: "Color Order:",
      //   options: colorOrder,
      //   placeholder: "None",
      // }),
      // Included in the figma but might need more logic to be implemented
      DividerAttr(),
      HeadingAttr({
        title: "Bar Formatting",
        tooltip: null,
      }),
      SpinnerAttr({
        label: "Width:",
        value: design.width,
        step: 0.1,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ width: data }),
        codeKey: "width",
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.dodge,
        onChange: (_, e) => updateDesign({ dodge: e.checked }),
        label: "Dodge:",
        codeKey: "dodge",
        codeRequiresInteraction: true,
        dataTestID: "dodge",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top:",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right:",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
        ],
      }),
    ],
  };
  return countChart;
};

const CountForm = () => {
  const countChart = CountContext();
  return buildReactFromAttrs(countChart.baseAttrs);
};

export const CountDesign = () => {
  const countDesign = CountContext();
  return buildReactFromAttrs(countDesign.designAttrs);
};

export default CountForm;
