import React from "react";
import { RangeSelection, getAliasFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { Chart, UIAttr, areDependenciesSatisfied, Dependency } from "./types";
import { PlotType, PlotLibrary } from "../../../hooks/plots/PlotTypes";
import { getPythonLocation } from "../../../../excel/grid-utils";
import { PythonExecutionLocation } from "../../../../excel/types";

export interface CodeBuilder {
  library: PlotLibrary;
  chartType: PlotType;
  extraImports: string[];
  source: RangeSelection;
  plotAttrs: string[]; // comma-separated attributes inside the plot call, i.e. `hue='Species'`
  plotCalls: string[]; // items like `plt.ylabel('Year')` which come after the initial plot call
  hasHeaders?: boolean;
}

export const SeabornChartTypeMap = {
  bar: "barplot",
  line: "lineplot",
  box: "boxplot",
  regression: "regplot",
  scatter: "scatterplot",
  violin: "violinplot",
  count: "countplot",
  pairwise: "pairplot",
  distribution: "displot",
};

export function buildReactFromAttrs(attrs: UIAttr[]) {
  return (
    <>
      <div style={{ paddingBottom: 5 }}>
        {attrs.map((attr: UIAttr, index: number) => {
          if (areDependenciesSatisfied(attr.visibleDependencies)) {
            return <div key={index}>{attr.getReact()}</div>;
          }
        })}
      </div>
    </>
  );
}

export function buildCodeFromAttrs(attrs: UIAttr[], cb: CodeBuilder) {
  attrs.forEach((attr) => {
    if (!attr.getCode) return;
    if (!areDependenciesSatisfied(attr.visibleDependencies)) return;
    if ("enabledDependencies" in attr && !areDependenciesSatisfied(attr.enabledDependencies as Dependency[])) return;
    // All checks pass; include the attr in the code
    attr.getCode(cb);
  });
}

export async function buildCode(chart: Chart, attrs: UIAttr[]): Promise<CodeBuilder> {
  const cb: CodeBuilder = {
    library: chart.plotLibrary,
    chartType: chart.plotType,
    extraImports: [],
    source: chart.dataRange,
    plotAttrs: [],
    plotCalls: [],
    hasHeaders: chart.hasHeaders,
  };

  // TODO: move these once multiple plot libraries are in use
  if (chart.plotLibrary === PlotLibrary.seaborn) {
    if (chart.plotType === PlotType.regression) {
      cb.extraImports.push("import numpy as np");
      cb.extraImports.push("import statsmodels");
    }
  }

  buildCodeFromAttrs(attrs, cb);

  return cb;
}

export async function buildPythonInExcelCode(cb: CodeBuilder, userEditor: boolean): Promise<string> {
  if (cb.library === PlotLibrary.seaborn) return buildSeabornPythonInExcelCode(cb, userEditor);
  throw Error(`Unsupported library: ${cb.library}`);
}

export async function buildPyodideCode(cb: CodeBuilder): Promise<string> {
  if (cb.library === PlotLibrary.seaborn) return buildSeabornPyodideCode(cb);
  throw Error(`Unsupported library: ${cb.library}`);
}

function handleHeaders(executionLocation: PythonExecutionLocation, code: string[], cb: CodeBuilder, dfAlias: string) {
  if (executionLocation === PythonExecutionLocation.Local) {
    if (cb.hasHeaders) {
      code.push(`data = REF("${dfAlias}")\ndf = pd.DataFrame(data[1:], columns=data[0])\n\n`);
    } else {
      code.push(`data = REF("${dfAlias}")\ndf = pd.DataFrame(data)\n\n`);
    }
  } else {
    let headers = cb.hasHeaders.toString().charAt(0).toUpperCase() + cb.hasHeaders.toString().slice(1);
    code.push(`df = xl("${dfAlias}", headers=${headers})\n\n`);
  }
}

async function buildSeabornPythonInExcelCode(cb: CodeBuilder, userEditor: boolean): Promise<string> {
  let code = [];
  const executionLocation: PythonExecutionLocation = await getPythonLocation();
  // Extra imports
  cb.extraImports.forEach((imp: string) => {
    code.push(`${imp}\n`);
  });
  // Standard imports
  code.push("import seaborn as sns\nimport matplotlib.pyplot as plt\n\n");
  // Data source
  const dfAlias = await getAliasFromRangeSelection(cb.source);

  handleHeaders(executionLocation, code, cb, dfAlias);
  // Start of plot object
  if (userEditor) {
    code.push(`''' Editable beyond this line '''\n`);
  }
  code.push(`ax = sns.${SeabornChartTypeMap[cb.chartType]}(data=df`);
  // Add plot attrs
  cb.plotAttrs.forEach((pa: string) => {
    code.push(`, ${pa}`);
  });
  code.push(")\n");
  // Add plot calls
  cb.plotCalls.forEach((pc: string) => {
    code.push(`\n${pc}`);
  });

  code.push("\nplt.tight_layout()\n\nplt.show()");

  return code.join("");
}

async function buildSeabornPyodideCode(cb: CodeBuilder): Promise<string> {
  let code = [];
  // Extra imports
  cb.extraImports.forEach((imp: string) => {
    code.push(`${imp}\n`);
  });
  // Start of plot object
  code.push(`ax = sns.${SeabornChartTypeMap[cb.chartType]}(data=df`);
  // Add plot attrs
  cb.plotAttrs.forEach((pa: string) => {
    code.push(`, ${pa}`);
  });
  code.push(")\n");
  // Add plot calls
  cb.plotCalls.forEach((pc: string) => {
    code.push(`\n${pc}`);
  });

  code.push("\nplt.tight_layout()\n\nplt.show()");

  return code.join("");
}
